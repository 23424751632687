export const ADD_VALUE = "ADD_VALUE";
export const SET_VALUE = "SET_VALUE";

export const addValue = (value) => ({
  type: ADD_VALUE,
  payload: value,
});

export const setValue = (value) => ({
  type: SET_VALUE,
  payload: value,
});