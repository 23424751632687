//Reception Page
// Chakra imports
import {
  Show,
  Hide,
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,Button, ButtonGroup,Input ,Stack,InputGroup,InputLeftElement,InputRightElement,
  Radio, RadioGroup, Checkbox,
  Heading,  Skeleton, SkeletonCircle, SkeletonText, Text,TableContainer, Tr, Td, Table, Th, Thead, Tbody
} from "@chakra-ui/react";
// Assets
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import { useHistory } from "react-router-dom";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";

import {FcPhone,FcAlarmClock, FcAssistant,FcCalendar,   } from "react-icons/fc"
import {TbLetterD, TbLetterG, TbLetterS   } from "react-icons/tb"

import {PhoneIcon} from '@chakra-ui/icons'

//React imports
import { useEffect, useState } from "react";
import { isDefined } from "@chakra-ui/utils";
import { isEmpty } from "lodash";

//sockets for updates
import io from 'socket.io-client';
import { getCookie,dateFormatNormal } from "../../../utilities";

//Load redux
import {addValue} from '../../../redux/actions'
import { setSession , setTemplate, setDoctorList} from "redux/reducer";
import { useSelector, useDispatch } from "react-redux";
import { token } from "stylis";

var REACT_APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL
const socket = io(REACT_APP_SOCKET_URL);

var axios = require('axios')
//add default token
var {toTitleCase,dateFormat} = require('../../../utilities')
var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

function validateReceptionInput(data, comp) {
  console.log('Checking fields');
  console.table(data)
  console.log('comp', comp);
  if (isEmpty(data.phone))
    return {status:false, message:"Enter phone number"}
  else if (isEmpty(data.patient_name))
    return {status:false, message:"Enter patient name"}
  else if (isEmpty(data.address))
    return {status:false, message:"Enter patient address"}
  else if (isEmpty(data.age) && isEmpty(data.birthdate))
    return {status:false, message:"Enter age or birthdate"}
  else if (isEmpty(data.gender))
    return {status:false, message:"Enter gender"}
  else if (isEmpty(data.complaint) && comp.length<2)
    return {status:false, message:"Enter complaint"}
  else if (data.selected_doctor=="Select Doctor")
    return {status:false, message:"Please select a doctor"}
  else return {status:true}
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

async function validatePhone(data, setData) {
  console.log("Validating ", data.phone)
  var res = await axios.get(process.env.REACT_APP_BASE_URL + `/getPatientDetails?phone=${data.phone}`)
  console.log(res)
  if(res.data.status == "success"){
  
  // check if patient has appointment
  console.log(Object.keys(res.data));
  if(isDefined(res.data.result.appointment_id)) {
    setData({...data, appointment_id:res.data.result.appointment_id,
                      complaint:res.data.result.complaint,
                      urgent:res.data.result.urgent,
                      selected_doctor:res.data.result.selected_doctor,
                      isButtonSaveEnabled:true,
                      patient_name:toTitleCase(res.data.result.patient_name),
                      gender:res.data.result.gender, 
                      isvip:res.data.result.isvip,
                      email:res.data.result.email, 
                      birthdate:dateFormat(res.data.result.birthdate), 
                      age:res.data.result.age,
                      address:res.data.result.address,
                      patient_id:res.data.result.patient_id,
                      history:res.data.result.history
                      })
    
  } else 
  setData({...data, patient_name:toTitleCase(res.data.result.patient_name),
    gender:res.data.result.gender, 
    isvip:res.data.result.isvip,
    email:res.data.result.email, 
    birthdate:dateFormat(res.data.result.birthdate), 
    age:res.data.result.age,
    address:res.data.result.address,
    patient_id:res.data.result.patient_id,
    history:res.data.result.history })
}
}

  async function saveAppointment(data, setData, setOpdStats, state, comp, setComp) {
    
    console.log("Saving appointment")
   console.log(data);  
   //create appointment
   if(data.patient_id) {
     console.log('existing patient');

     var res = await axios.post(`${REACT_APP_BASE_URL}/createAppointment`,{
      
      "patient_id":data.patient_id,
      "complaint":comp.length>2 ? comp:data.complaint, 
      "urgent":data.urgent,
     "created_by":{"user_id":state.session.user_id,"name":state.session.username}, 
     //"orgid":"BELSARE",
     "checkin":false,
     //"opd_id": data.selected_doctor == "Deepti Pundle" ? 1 : data.selected_doctor == "Gauri Belsare" ? 2 : 3,
     //"opd_id":data.selected_doctor,
     "appointmentfor":data.appointmentfor, 
     "metadata":{
         "doctor":data.selected_doctor.username,
         doctor_id: data.selected_doctor.user_id,
         opd_id:data.selected_doctor.personal_details.opd
     }
  })
  if(res.data.status) {
    res.data.status=="success" ? alert(`Appointment booked for token ${res.data.result.token}`):
    console.log("Error--->",res.data);
    clearAllFields(data,setData, setComp)
  }

  console.log(res)

   //var res = await axios.post(`${{REACT_APP_BASE_URL}}/`)
   } else {
     console.log('Creating new patient');
    var res = await axios.post(`${REACT_APP_BASE_URL}/createNewPatient`,{
      "patient_name":data.patient_name,
    
    "phone":data.phone,
    "address":data.address,
    "isvip":data.isvip,
    "gender":data.gender,
    "birthdate":data.birthdate ? data.birthdate : "",
    "age": data.age ? data.age : "",
    "email":data.email
    })
    //now create an appointment
    console.table(res)
    if(res.data.status=="success")
    var res_1 = await axios.post(`${REACT_APP_BASE_URL}/createAppointment`,{
      
        "patient_id":res.data.result[0],
        "complaint":comp.length>2 ? comp:data.complaint, 
        "urgent":data.urgent,
       "created_by":{"user_id":state.session.user_id,"name":state.session.username}, //add user context
       //"orgid":"BELSARE",
       "checkin":false,
       //"opd_id": data.selected_doctor == "Deepti Pundle" ? 1 : 2,
       //"opd_id":data.selected_doctor == "Deepti Pundle" ? 1 : data.selected_doctor == "Gauri Belsare" ? 2 : 3,
       //"opd_id":data.selected_doctor,
       "appointmentfor":data.appointmentfor, 
       "metadata":{
        "doctor":data.selected_doctor.username,
        doctor_id: data.selected_doctor.user_id,
        opd_id:data.selected_doctor.personal_details.opd
       }
    })
    console.log(res_1)
    if(res_1.data && res_1.data.status=="success") {
      alert(`Appointment booked for token ${res_1.data.result.token}`)
      clearAllFields(data,setData, setComp)
    }
   }
   getReceptionStats(setOpdStats); //refresh view
  }

  async function saveAndCheckin(data, setData, setOpdStats, state,comp, setComp) {
   //create appointment
   if(data.patient_id) {
     console.log('existing patient');
 
     var res = await axios.post(`${REACT_APP_BASE_URL}/createAppointment`,{
      
      "patient_id":data.patient_id,
      "complaint":comp.length>2 ? comp:data.complaint, 
      "urgent":data.urgent,
     "created_by":{"user_id":state.session.user_id,"name":state.session.username},
     //"orgid":"BELSARE",
     "checkin":true,
     //"opd_id": data.selected_doctor == "Deepti Pundle" ? 1 : 2,
     //"opd_id":data.selected_doctor == "Deepti Pundle" ? 1 : data.selected_doctor == "Gauri Belsare" ? 2 : 3,
     //"opd_id":data.selected_doctor,
     "appointmentfor":data.appointmentfor, 
     "metadata":{
      "doctor":data.selected_doctor.username ? data.selected_doctor.username : data.selected_doctor,
      doctor_id: data.selected_doctor.user_id ? data.selected_doctor.user_id : "",
      opd_id:data.selected_doctor.personal_details ? data.selected_doctor.personal_details.opd : ""
     }
  }) 
  if(res.data.status) {
    res.data.status=="success" ? alert(`Patient added to waiting area `):
    alert('Patient already in waiting area');
    clearAllFields(data,setData, setComp)
  }

  console.log(res)

   //var res = await axios.post(`${{REACT_APP_BASE_URL}}/`)
   } else {
     console.log('Creating new patient');
    var res = await axios.post(`${REACT_APP_BASE_URL}/createNewPatient`,{
      "patient_name":data.patient_name,
    
    "phone":data.phone,
    "address":data.address,
    "isvip":data.isvip,
    "gender":data.gender,
    "birthdate":data.birthdate ? data.birthdate : "",
    "age": data.age ? data.age : "",
    "email":data.email,
    
    })
    //now create an appointment
    console.table(res.data)
    if(res.data.status=="success") {
    var res_1 = await axios.post(`${REACT_APP_BASE_URL}/createAppointment`,{
      
        "patient_id":res.data.result[0],
        "complaint":comp.length>2 ? comp:data.complaint, 
        "urgent":data.urgent,
       "created_by":{"user_id":state.session.user_id,"name":state.session.username},
       //"orgid":"BELSARE",
       "checkin":true,
       //"opd_id": data.selected_doctor == "Deepti Pundle" ? 1 : 2,
       //"opd_id":data.selected_doctor == "Deepti Pundle" ? 1 : data.selected_doctor == "Gauri Belsare" ? 2 : 3,
       //"opd_id":data.selected_doctor,
       "appointmentfor":data.appointmentfor, 
       "metadata":{
        "doctor":data.selected_doctor.username,
        doctor_id: data.selected_doctor.user_id,
        opd_id:data.selected_doctor.personal_details.opd
       }
    })
    console.log('Printing res 1');
    console.table(res_1)
    if(res_1.data.status=="success") {
      alert(`New walk in Patient added to waiting for token ${res_1.data.result.token}`)
      clearAllFields(data,setData,setComp)
    }
    }
   }
   getReceptionStats(setOpdStats) // refresh view
  }


  function clearAllFields(data, setData, setComp) {
    console.log('clearing fields new state is');
    console.table(data)
    setData({...data,
    patient_id:"",
    appointment_id:"",
    token:"",
    phone:"",
    patient_name:"",
    gender:"",
    address:"",
    urgent:false,
    isvip:false,
    selected_doctor:"Select Doctor",
    complaint:"",
    email:"",
    birthdate:"",
    age:"",
    history:"",
    isButtonSaveEnabled:false,
  appointmentfor:dateFormat(new Date())})

  setComp("")
  }

  async function getReceptionStats(setOpdStats) {
    console.log('Setting opd status');
    var res = await axios.get(`${REACT_APP_BASE_URL}/getReceptionStats`)
    res = res.data // format 
    console.log("res",res.result);
    if(res.status=="success")
    setOpdStats({
    opdWaiting:res.result.opd_waiting,
    total_appointments:res.result.total_appointments,
    doctors:res.result.doctors
    })
  }
export default function UserReports() {
  
  //Effects function and setup Hooks
  const [hasAccess, setHasAccess] = useState(false);
  const state = useSelector(state => state);
  const dispatch = useDispatch();

  var [doctor_list, setDoctor_list] = useState([])
  const history = useHistory()
  var [comp, setComp] = useState("")
  var [phoneValid, setPhoneValid] = useState(false)
  var [data, setData] = useState({
    phone:"",
    patient_name:"",
    gender:"",
    address:"",
    urgent:false,
    isvip:false,
    selected_doctor:"Select Doctor",
    complaint:"",
    email:"",
    birthdate:"",
    age:"",
    patient_id:"",
    isButtonSaveEnabled:false,
    appointmentfor:dateFormat(new Date())

  },[])
  var [time, setTime] = useState(formatAMPM(new Date))
  var [isDisabledSaveButton, setIsDisabledSaveButton ] = useState(false)
  var [opdStats, setOpdStats] = useState({
    opdWaiting:0,
    d1:0,
    d2:0,
    d3:0,
    total_appointments:0,
    doctors:{}
  })
  useEffect(async () => {
    
    var user = getCookie('user');
    //Push cookie value to store
    var token = user.token
    axios.defaults.headers.common['x-access-token'] = token
    
    //get list of doctors
    var doctor_list_res = await axios.get(`${REACT_APP_BASE_URL}/getDoctorList`)
    console.log('Doctor list', doctor_list_res.data.doctor_list)
    setDoctor_list(doctor_list_res.data.doctor_list)
    dispatch(setSession())
    dispatch(setTemplate())
    dispatch(setDoctorList())
  
    //
    if(user!="") {
    if(user.response.user_role.permissions.includes("reception")) {
      console.log('Doctor view');
      setHasAccess(true)
    }
    else {
      console.log('No doctor access');
      setHasAccess(false)
    }
  }
    // Update the document title using the browser API
    console.table(data);
    getReceptionStats(setOpdStats)
    //get OPD details - loop; remove this
    setInterval(()=>{setTime(formatAMPM(new Date));
                      getReceptionStats(setOpdStats)
                    },600000)


    socket.on('connect', () => {
      console.log('Socket connected');
    });

    socket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    socket.on('pong', (a) => {
      console.log('Socket pong ',a);
    });

    socket.on('queue_update', (a) => {
      getReceptionStats(setOpdStats)
      console.log('appointment ping captured and updated',a);
    });

   


    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };
  },[]);
  
  //Autofill
  const items = [
    {
      id: 0,
      name: 'Ear Pain'
    },
    {
      id: 1,
      name: 'Ear Discharge'
    }
  ]

  const handleOnSearch = (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
   console.log('Typing');
    //setData({...data,complaint:string}) //recent edit change 14 oct
    setComp(string)
  }

  const handleOnHover = (result) => {
    // the item hovered

    console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    setData({...data,complaint:item.name})
    setComp(item.name)
    console.log("selected item ", item)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    
    //hasAccess
     state.session.permissions.includes("reception") ? 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
   
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <a href="/#/admin/appointments"><MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={FcPhone} color={brandColor} />
              }
            />
          }
          name="Appointments"
          value={opdStats.total_appointments}
        /> </a>
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={FcAlarmClock} color={brandColor} />
              }
            />
          }
          name="OPD Waiting"
          value={opdStats.opdWaiting}
        />

        <Hide below='md'>
          {/*Display dynamic doctor stats*/}
          {
            opdStats.doctors.length>0 ? opdStats.doctors.map(a=><MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon w="32px" h="32px" as={TbLetterD} color={brandColor} />
                  }
                />
              }
              name={a.name}
              value={a.count}
            />) : ""
          }
        </Hide>
      </SimpleGrid>

      {/*<!Form layout>*/}

      <Stack spacing={3} borderWidth='0px' >

        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<PhoneIcon color="gray.300" />}
          />
          <Input width="300px" variant="filled" type="input" placeholder="Phone number" 
         //  errorBorderColor='crimson'
           focusBorderColor={phoneValid}
           borderColor={phoneValid == 'crimson' ? 'crimson' : "green.300"}
         //  isInvalid={phoneValid}
          onChange={(a)=>{setData({...data,phone:a.target.value});
                          data.phone.length==9 ? setPhoneValid('blue') : setPhoneValid('crimson')}}
          onBlur={()=>validatePhone(data, setData)}
          value = {data.phone}
          autoFocus/>

          
          <label size="md">{"Appointment for :"}</label><Input type='date' width="20%" 
          value={data.appointmentfor} onChange={(a)=>{setData({...data,appointmentfor:a.target.value})}}/>
        </InputGroup>

        <Stack spacing={3} direction={{base:"row", lg:"row", md:"row", sm:"column"}}>
          <Input variant="filled" placeholder="Name" value={data.patient_name}
          onChange={(a)=>{setData({...data,patient_name:a.target.value});}}/>
          <Input variant="filled" placeholder="Age" value={data.age} width="500px" 
          onChange={(a)=>{setData({...data,age:a.target.value});}}/>
        </Stack>

        <Stack spacing={3} direction={{base:"row", lg:"row", md:"row", sm:"column"}}>
        <RadioGroup value={data.gender} 
    
        onChange={(a)=>{console.log(a);setData({...data,gender:a});}}>
        <Stack direction="row">
          <Radio value="M" >Male</Radio>
          <Radio value="F" >Female</Radio>
        </Stack>
      </RadioGroup>
          <Input variant="filled" placeholder="Address" value={data.address}
          onChange={(a)=>{setData({...data,address:a.target.value});}} />
        </Stack>
       
        <Stack spacing={3} direction={{base:"row", lg:"row", md:"row", sm:"column"}}>
        
        <div style={{ width : "50%" }}>
        <ReactSearchAutocomplete
            items={state.templates.complaints} //dynamic items state.templates.complaints
            onSearch={(string)=>handleOnSearch(string,data)}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            //autoFocus
            formatResult={formatResult}
            styling={{
              borderRadius: "7px",
              boxShadow: "0px",
              zIndex: '99',
              
            }}
            placeholder={"Complaint"}
            showIcon = {false}
            inputSearchString = {data.complaint}
            
            
          />
  </div>

        
        <Checkbox variant="filled" width="100" size='lg' isChecked={data.urgent}
        onChange={(a)=>{setData({...data,urgent:a.target.checked});}}>Urgent</Checkbox>
        <Checkbox variant="filled" width="100" size='lg' isChecked={data.isvip}
        onChange={(a)=>{setData({...data,isvip:a.target.checked});}}>VIP</Checkbox>
        </Stack>
     
        <Select placeholder="Select Doctor" variant="filled" value={JSON.stringify(data.selected_doctor)}
        onChange={(a)=>{setData({...data,selected_doctor:JSON.parse(a.target.value)});}}>
    
      {
        doctor_list.map(a=>  <option value={JSON.stringify(a)}>{a.username}</option> )
      }
</Select>

<Stack spacing={3} direction={{base:"row", lg:"row", md:"row", sm:"column"}}>
          <Input variant="filled" placeholder="Email" value={data.email}
          onChange={(a)=>{setData({...data,email:a.target.value});}} />
         <label size="md">{"Date of Birth :"}</label> <Input variant="filled" placeholder="Date of Birth" width="50%" type="date" 
          value={data.birthdate}
          onChange={(a)=>{console.log(a); setData({...data,birthdate:a.target.value});}}/>
        </Stack>


      <ButtonGroup gap="4" display={'flex'} flexWrap={'wrap'} >
        <Button shadow="md" colorScheme="blue"
          onClick={()=> {
            validateReceptionInput(data, comp).status ? saveAppointment(data, setData, setOpdStats, state, comp, setComp) : alert(validateReceptionInput(data, comp).message) 
           }}
          isDisabled={data.isButtonSaveEnabled}>Save Appointment</Button>
        <Button shadow="md" colorScheme="green"
          onClick={()=> {
            validateReceptionInput(data, comp).status ? saveAndCheckin(data, setData, setOpdStats, state, comp, setComp) : alert(validateReceptionInput(data, comp).message)
           }}>Save & Check-in</Button>
        <Button shadow="md" colorScheme="red" onClick={()=>clearAllFields(data,setData,setComp)}>Clear</Button>

      
      </ButtonGroup>

{data.history ? <TableContainer>
  <Table variant='simple'>
    <Thead>
      <Tr>
        <Th>Date</Th>
        <Th>Complaint</Th>
        <Th>Doctor</Th>
      </Tr>
    </Thead>
    <Tbody>
{data.history.map(a=> 
      <Tr  color= {a.iscomplete=='true' ? 'green' : 'red'}> 
        <Td>{dateFormatNormal(a.appointmentfor)}</Td>
        <Td >{a.updated_complaint?a.updated_complaint:a.complaint}</Td> 
        <Td>{a.doctor}</Td>
      </Tr> )
}
    </Tbody>
  </Table>
</TableContainer> : ''}

      </Stack>
    </Box>  :  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Heading>You do not have access to view this page</Heading>
    </Box>

  );
}


//complaints should have autofill
//address should have autofill
//token number is for every patient - validity 1 day, 4am
//token response system
//auto gen message before 1 hour
