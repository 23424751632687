
// Chakra imports
import { Box, Grid,
Heading, Stack, Button, Input,
TableContainer, Table, Tr, Td, Thead, Tfoot,
TableCaption, Th, Tbody,Switch, Icon, Checkbox, CheckboxGroup } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import {FcApproval} from 'react-icons/fc'
import { getCookie, msToTime, setCookie } from "../../../utilities";
import AppointmentList from './components/ColumnsTable'
// Custom components
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  RadioGroup,
  Radio
} from '@chakra-ui/react'

import Banner from "views/admin/billing/components/Banner";
import General from "views/admin/billing/components/General";
import Notifications from "views/admin/billing/components/Notifications";
import Projects from "views/admin/billing/components/Projects";
import Storage from "views/admin/billing/components/Storage";
import Upload from "views/admin/billing/components/Upload";
import ReactToPrint from 'react-to-print';

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import React from "react";
import { useState, useEffect,useRef } from "react";
import { dateFormat } from "utilities";
import { isDefined } from "@chakra-ui/utils";
import { isEmpty } from "lodash";
import Webcam from "react-webcam";
import { Receipt } from "./components/Receipt";
import tableDataColumns from "views/admin/doctor/variables/tableDataColumns.json";
import {columnsDataColumns} from './components/columnsData'
import io from 'socket.io-client';
import { addValue } from "redux/actions";

import { setSession , setTemplate} from "redux/reducer";
import { store } from "redux/store";
import { useSelector, useDispatch} from "react-redux";
var {dateFormatNormal, toTitleCase, copyJSON} = require('../../../utilities')



var axios = require('axios')
var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
var REACT_APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL

const socket = io(REACT_APP_SOCKET_URL);

//photo getAppointments
async function getAppointmentData(appointmentData,selectedDate, setAppointmentData, dispDoctor, setFilterData) {

  //dispatch(addValue({ 9 April dispatch comment
    //filter_appointment:dispDoctor}))
  var res = await axios.get(`${REACT_APP_BASE_URL}/getAppointments?date=${selectedDate}`)
  console.log('app',res.data);
  if(isDefined(res.data.result)){
    var tmp = res.data.result
    tmp.map((a,i)=>{
      console.log('waittime', msToTime(Date.now() - new Date(a.checked_at)));
      if(a.ischecked) tmp[i].waittime= msToTime(Date.now() - new Date(a.checked_at))
    })
    console.log('tmp',tmp);
    setAppointmentData(tmp) //9 nov try fix to unnecessary update

    /*var dd = []
    tmp.map(a=>{
     if(dispDoctor[a.doctor]) dd.push(a)
    })
    console.log('filter', dd)
    
    console.log('')
    setFilterData(dd)*/
    if(dispDoctor){
    var dd = []
    tmp.map(a=>{
      dispDoctor.map(b=> {
        if(a.doctor==b.username && b.visible) dd.push(a)
      })
     })
     setFilterData(dd)
    }
    else  setFilterData(tmp)

  }
  else  setAppointmentData([])
}


//Function to set and persist filter
async function persistFilter(data) {
  console.log('Saving filter cookie')
 var tmp = setCookie('doctor_filter', data, 10)
}

export default function Appointments() {

  //const [dateSelector, setDateSelector] = useState(false)
  const [selectedDate, setSelectedDate] = useState(dateFormat(new Date().toLocaleString("en-US")))
  const [appointmentData, setAppointmentData] = useState({})
  const [filterData, setFilterData] = useState([])
  const [doctorFilter, setDoctorFilter] = useState([])
  //redux
  var ss = useSelector(state => state);
  var dispatch = useDispatch()

  console.log('ss',ss);
  //hardcode
  const [dispDoctor, setDispDoctor] = useState([])
    //"Sai Belsare":true,
    //"Deepti Pundle":true,
    //"Gauri Belsare":true
  //})  

  const [doctorList, setDoctorList] = useState([])  

  //const [clickedPatient, setClickPatient] = useState()
  //const [imageSrc, setImageSrc] = useState()
  //const [printData, setPrintData] = useState({})
  const [hasAccess, setHasAccess] = useState(false);
  //const { isOpen, onOpen, onClose } = useDisclosure()
  //const componentRef = useRef(); //print
  var user = getCookie('user');

  useEffect(async ()=>{

    //Get doctor list
    var doctor_list_res = await axios.get((`${REACT_APP_BASE_URL}/getDoctorList`))
    console.log('doc', doctor_list_res.data.doctor_list)
    setDoctorList(doctor_list_res.data.doctor_list)

    //set dispDoctor from cookie
    var cookie_list = getCookie('doctor_filter')
    
   if(cookie_list) {
    console.log('Exists cookie')
    setDispDoctor(cookie_list)
    }
    else {
      console.log('New cookie')
    var _tmp = []
    doctor_list_res.data.doctor_list.map(a=>_tmp.push({user_id:a.user_id, username:a.username, visible:true}))
    setDispDoctor(_tmp)
    persistFilter(_tmp)
    }

    //
    if(user.response.user_role.permissions.includes("reception")) {

      setHasAccess(true)
    }
    else {
      
      setHasAccess(false)
    }

    // getAppointmentData(appointmentData,selectedDate, setAppointmentData, dispDoctor, setFilterData, dispatch,addValue) {
    console.log('Disp doctor', dispDoctor)
    getAppointmentData(appointmentData,selectedDate,setAppointmentData, getCookie('doctor_filter') ,setFilterData) 
    
    socket.on('queue_update', (a) => {
      //getAppointmentData(appointmentData,selectedDate,setAppointmentData, dispDoctor, setFilterData)
      getAppointmentData(appointmentData,selectedDate,setAppointmentData, getCookie('doctor_filter') ,setFilterData) 

      console.log('appointment ping captured and updated',a);
    });

    socket.on('appointment_update', (a) => {
      //getAppointmentData(appointmentData,selectedDate,setAppointmentData, dispDoctor, setFilterData)
      getAppointmentData(appointmentData,selectedDate,setAppointmentData, getCookie('doctor_filter') ,setFilterData) 

      console.log('appointment ping captured and updated',a);
    });

  },[])

  function checkboxSelectHandler(option, dr, doctorList, appointmentData, setFilterData, dispDoctor, setDispDoctor, persistFilter) {
  //function checkboxSelectHandler(option, doctor, appointmentData, setFilterData, dispDoctor, setDispDoctor) {
    console.log('Applying filter - ', doctorList)
    console.log('Dr - ', dr)
    var _filter = []
    dispDoctor.map(a=>{ if(a.user_id==dr.user_id) _filter.push({user_id:a.user_id, username:a.username, visible:option})
      else  _filter.push(a) })

    console.log('Filter function', _filter)
    setDispDoctor(_filter)
    persistFilter(_filter)
    console.log('Appointment data')
    console.table(appointmentData)
    var dd = []
    appointmentData.map(a=>{
      _filter.map(b=> {
        if(a.doctor==b.username && b.visible) dd.push(a)
      })
     })
     setFilterData(dd)
    //doctor list, selected doctor, total appointment data, filtered data, functions for this
    
   //setDispDoctor({...dispDoctor, [doctor]:option})

   /*console.log(dispDoctor)
    var dd = []
    //var tmp_ = {...dispDoctor, [doctor]:option}
    appointmentData.map(a=>{
     if(tmp_[a.doctor]) dd.push(a)
    })
    console.log('filter', dd)
    
    
    setFilterData(dd)*/

  }

  return (
    hasAccess ? 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
 
      {/* Main Fields */}
      <Stack spacing={3}>
       <Stack spacing={3} direction="row">
       <Input variant="filled" placeholder="Select Date" width={{base:"25%", sm:"80%", lg:"25%", md:"30%" }}
          type="date" 
          value={selectedDate}
          onChange={(a)=>{console.log('date', a.target.value);
          setSelectedDate(a.target.value)}}
          focusBorderColor='blue.300'
          //autoFocus="true"
           />


        <Button colorScheme={'blue'} width="15%"
        onClick={()=>getAppointmentData(appointmentData,selectedDate,setAppointmentData, dispDoctor, setFilterData,dispatch,addValue)}>List</Button>


          <CheckboxGroup >
        {dispDoctor ? dispDoctor.map(dr=><Checkbox isChecked={dr.visible}
         //onChange={(a)=>checkboxSelectHandler(a.target.checked, 'Deepti Pundle', appointmentData, setFilterData, dispDoctor, setDispDoctor,dispatch,addValue)}>
         onChange={(a)=>checkboxSelectHandler(a.target.checked,dr, doctorList,appointmentData, setFilterData, dispDoctor, setDispDoctor, persistFilter)}>
           {dr.username}
         </Checkbox>): ""}

         </CheckboxGroup>
       {/* 
          <CheckboxGroup >
         <Checkbox isChecked={dispDoctor['Deepti Pundle']}
         onChange={(a)=>checkboxSelectHandler(a.target.checked, 'Deepti Pundle', appointmentData, setFilterData, dispDoctor, setDispDoctor,dispatch,addValue)}>
           Deepti Pundle
         </Checkbox>
         <Checkbox isChecked={dispDoctor['Gauri Belsare']}
         onChange={(a)=>checkboxSelectHandler(a.target.checked, 'Gauri Belsare', appointmentData, setFilterData, dispDoctor, setDispDoctor,dispatch,addValue)}>
           Gauri Belsare
         </Checkbox>
         <Checkbox isChecked={dispDoctor['Sai Belsare']}
         onChange={(a)=>checkboxSelectHandler(a.target.checked, 'Sai Belsare', appointmentData, setFilterData, dispDoctor, setDispDoctor,dispatch,addValue)}>
           Sai Belsare
         </Checkbox>
  </CheckboxGroup> */}
          </Stack>
          {appointmentData.length>0 ?  <AppointmentList 
          columnsData={columnsDataColumns}
          tableData={filterData}
          /> : "" }

       </Stack>


    </Box> :  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Heading>You do not have access to view this page</Heading>
    </Box>
  );
}
