import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Image , Text} from "@chakra-ui/react";

// Custom components
import { HorizonLogo,AppleLogo, ENT, Bent } from "components/icons/Icons"; //Logo for product
import { HSeparator } from "components/separator/Separator";
//import {img} from "assets/img/layout/BelsareENT.png"
import img from "assets/img/layout/CloudOPD_logo.png"

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  //#edit logo 
  return (
    
    <Flex align='center' direction='column'>
      <Image src={img} maxWidth="85%"/>
       
      <HSeparator mb='20px' />

    </Flex>
    
  );
}

export default SidebarBrand;
//<HorizonLogo h='26px' w='175px' my='32px' color={logoColor} />