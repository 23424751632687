
// Chakra imports
import { Box, SimpleGrid, Icon , useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Text,
  Heading,
  Hide
  
} from "@chakra-ui/react";
import PieCard from "views/admin/doctor/components/PieCard";
import Waitlist from "views/admin/doctor/components/ComplexTable";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import MiniCalendar from "components/calendar/MiniCalendar";
import {useHistory}  from "react-router-dom";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";

import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/doctor/variables/columnsData";
import tableDataDevelopment from "views/admin/doctor/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/doctor/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/doctor/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/doctor/variables/tableDataComplex.json";
import React from "react";

import { useEffect, useState } from "react";
import { isDefined } from "@chakra-ui/utils";
import { isEmpty, lowerCase } from "lodash";
import {getCookie, setCookie} from '../../../utilities'
import { useDispatch, useSelector } from "react-redux";
import {setSession} from '../../../redux/reducer'
var {dateFoformatAMPM} = require('../../../utilities')

var axios = require('axios')
var {formatAMPM,dateFormat} = require('../../../utilities')
var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL


//Patient Modal
function BasicUsage() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen}>Open Modal</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Patient Name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Waitlist
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
          </ModalBody>

          <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={()=>console.log("Saving patient data API")}>
              Save
            </Button>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

async function getOpdStatus(setOpdStatus) {
  var user = getCookie('user')
  var res = await axios.get(`${REACT_APP_BASE_URL}/getOpdStats?doctor=${lowerCase(user.response.username)}`)
    console.log(res);
    if(res.data.status=="success")
    setOpdStatus({
    complete:parseInt(res.data.result.complete),
    total_appointments:parseInt(res.data.result.total_appointments),
    urgent:parseInt(res.data.result.urgent),
    waiting:parseInt(res.data.result.waiting)
    })
}

//Use websockets for list updates in V2
async function updateList(setPatientWaitList) {
  var user = getCookie('user')
  var res = await axios.get(`${REACT_APP_BASE_URL}/getPatientWailist?doctor=${lowerCase(user.response.username)}`)
  console.log('wailist',res);
  if(res.data.status=="success")
  //parse time in readable format
  var list = res.data.result
  list.map((a,i)=>list[i].time=formatAMPM(new Date(a.time)))

  setPatientWaitList(list)

}

export default function Settings() {

  console.log('Shree Ganesh');
  //Get context of doctor from cookie
  const [hasAccess, setHasAccess] = useState(false);
  const state = useSelector(state => state)
  const dispatch = useDispatch()
  const [opdStatus, setOpdStatus] = useState({
    completed:0,
    total_appointments:0,
    urgent:0,
    waiting:0
  })
  const [patientWaitList, setPatientWaitList] = useState([])
  
  useEffect(async () => {
    // Update the document title using the browser API

    var user = getCookie('user');
    dispatch(setSession())
    //
    if(user.response.user_role.permissions.includes("doctor")) {
      console.log('Doctor view');
      setHasAccess(true)
    }
    else {
      console.log('No doctor access');
      setHasAccess(false)
    }

     //Get opd status 
     getOpdStatus(setOpdStatus);

     //set waitlist
     updateList(setPatientWaitList);

    setInterval(()=>{
        getOpdStatus(setOpdStatus);
        updateList(setPatientWaitList);
    },60000)
    

  },[]);

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  
  const history = useHistory()
  // Chakra Color Mode
  
 return (
   //hasAccess
   state.session.permissions.includes("doctor") ? 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 3 }}
        spacing={{ base: "20px", xl: "20px" }}>
          <PieCard pieChartData = {[opdStatus.waiting, opdStatus.complete, opdStatus.urgent]}/>
          <Hide below='md'>
          <SimpleGrid
        mb='20px'
        rows={{ sm: 1, md: 3 }}
        spacing={{ base: "20px", xl: "20px" }}>
          <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Appointments"
          value={opdStatus.total_appointments}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Completed"
          value={opdStatus.complete}
        />
        </SimpleGrid>
        <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </Hide>
          </SimpleGrid>
          
        <Waitlist
          columnsData={columnsDataComplex}
          tableData={patientWaitList}
        />
      
    </Box> 
    //No access then
    : <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Heading>You do not have access to view this page</Heading>
    </Box>
  ); 

  
}
