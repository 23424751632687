
// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,Button, ButtonGroup,Input ,Stack,InputGroup,InputLeftElement,InputRightElement,
  Radio, RadioGroup, Checkbox, Text,Textarea, Heading, Th, Tbody,
  TableContainer, Table, Tr, Td, Thead, Tfoot, Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure, InputLeftAddon
} from "@chakra-ui/react";
// Assets
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Usa from "assets/img/dashboards/usa.png";
import { useHistory } from "react-router-dom";
// Custom components
import React from "react";
import Card from "components/card/Card";

import { useEffect, useState, useRef } from "react";
import { isDefined } from "@chakra-ui/utils";
import { isEmpty, lowerCase } from "lodash";
import {getCookie, setCookie} from '../../../utilities'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {setSession, listPatients, updatePatients} from '../../../redux/reducer'
import { addValue } from "redux/actions";

var {toTitleCase, dateFormat} = require('../../../utilities')

var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

//function definitions
async function filterList(dispatch, searchOption,val) {
  
  //submit to db
  var res = await axios.get(`${REACT_APP_BASE_URL}/admin/searchPatients?&${searchOption}=${val.toLowerCase()}`)
  if(res.data.status=="success") {
    dispatch(addValue({patient_list:res.data.result}))
    
  }
}

async function clearSearch(dispatch,listPatients,setSearchValue) {
  dispatch(listPatients())
  setSearchValue("")
  console.log('Clear');
}

//save data
async function saveChanges(edit, dispatch, updatePatients, onClose) {
  var body = {
    "field":"patient_id",
    "value": edit.patient_id,
    "updatedBy":"",
    "update":{
      ...edit
    }
}

dispatch(updatePatients(body))

  console.table(edit);
  onClose()
}

 //Modal
  function EditModal(isOpen, onOpen, onClose, edit, setEdit, dispatch,updatePatients) {
  
  return (
    <>
      

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{edit.patient_name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Stack spacing={4}>
          <InputGroup>
              <InputLeftAddon children='Name' bgColor='gray.100'/>
              <Input value = {edit.patient_name} onChange={(a)=>setEdit({...edit,patient_name:a.target.value})}/>
          </InputGroup>

          <InputGroup>
              <InputLeftAddon children='Phone' />
              <Input value = {edit.phone} onChange={(a)=>setEdit({...edit,phone:a.target.value})} />
          </InputGroup>

          <InputGroup>
              <InputLeftAddon children='Email' />
              <Input value = {edit.email} onChange={(a)=>setEdit({...edit,email:a.target.value})}/>
          </InputGroup>

          
          <RadioGroup value= {edit.gender} onChange={(a)=>setEdit({...edit,gender:a})}>
        <Stack direction="row">
          <Radio value="M" >Male</Radio>
          <Radio value="F" >Female</Radio>
        </Stack>
      </RadioGroup>
         
          

          <InputGroup>
              <InputLeftAddon children='Address' />
              <Input value = {edit.address} onChange={(a)=>setEdit({...edit,address:a.target.value})}/>
          </InputGroup>

          <InputGroup>
              <InputLeftAddon children='Birthdate' />
              <Input value = { dateFormat(edit.birthdate || "1991-07-24")  } type="date"
              onChange={(a)=>setEdit({...edit,birthdate:a.target.value})} />
          </InputGroup>

          <Checkbox variant="filled" width="100" size='lg' isChecked={edit.isvip}
          onChange={(a)=>setEdit({...edit,isvip:a.target.checked})}
       >VIP</Checkbox>

          </Stack>
         
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme='blue' mr={3} onClick={()=>saveChanges(edit, dispatch, updatePatients, onClose)}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}


export default function PatientList() {

  const componentRef = useRef(); //print
  const state = useSelector(state=>state)
  const patient_list = useSelector(state=>state.patient_list)
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  var user = getCookie('user')
  const history = useHistory()
  const [hasAccess, setHasAccess] = useState(false);
  const [searchOption, setSearchOption] = useState('patient_name')
  const [searchValue, setSearchValue] = useState('')
  const [edit,setEdit ] = useState('')

 

  
  useEffect(async () => {
    // Update the document title using the browser API
    var user = getCookie('user');

    //set dispatch actions
    dispatch(setSession())

    //get patient list
    dispatch(listPatients())
    

    //
    if(user.response.user_role.permissions.includes("reception") || user.response.user_role.permissions.includes("doctor")) {
      setHasAccess(true)
    }
    else {
      
      setHasAccess(false)
    }


  },[]);

  

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
     
      hasAccess ?
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
       {EditModal(isOpen, onOpen, onClose, edit, setEdit, dispatch, updatePatients)}
       
      <Stack spacing={5}>
       
      <Stack direction="row">
        <Select width="25%" placeholder='Select value' variant="filled" defaultValue={'patient_name'}
        onChange={a=>setSearchOption(a.target.value)}>
      <option value='patient_name'>Name</option>
      <option value='phone'>Phone</option>
      <option value='email'>Email</option>
</Select>
      <Input width="250px" bgColor={"white"}
      placeholder = {searchOption=="patient_name" ? "NAME" : searchOption.toUpperCase()}
      onChange={(a)=>setSearchValue(a.target.value)}
      value = {searchValue}
      ></Input>
      <Button onClick={()=>filterList(dispatch, searchOption, searchValue)}>Search</Button>
      <Button onClick={()=>clearSearch(dispatch, listPatients, setSearchValue)}>Reset</Button>
      </Stack>
     <Card>

      {/*<!Form layout>*/}
      <TableContainer>
  <Table variant='simple'>
    <Thead>
      <Tr>
        <Th>Sr.</Th>
        <Th>Name</Th>
        <Th>Gender</Th>
        <Th>Phone</Th>
        <Th>Email</Th>
        <Th>Edit</Th>
        
        
      </Tr>
    </Thead>
    <Tbody>
     {patient_list ?
     patient_list.map((patient,i)=><Tr >

       <Td>{i+1}</Td>
       <Td onClick={()=>history.push('/admin/visits/'+patient.patient_id)}>{toTitleCase(patient.patient_name)} {patient.isvip ? <span role="img"> ⭐️</span> : ""}</Td>
       <Td>{patient.gender}</Td>
       <Td>{patient.phone}</Td>
       <Td>{patient.email}</Td>
       <Td><Button onClick={()=>{setEdit(patient) ; onOpen();}}><span role="img">✏️</span></Button></Td>
       
     </Tr>) : ""

     }
     
    </Tbody>
  </Table>
</TableContainer>
          </Card>
          </Stack>
    </Box>
    : <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <Heading>You do not have access to view this page</Heading>
  </Box>
  );
}


