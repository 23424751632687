
// Chakra imports
import { Box, Grid,
Heading, Stack, Button, Input,
TableContainer, Table, Tr, Td, Thead, Tfoot,
TableCaption, Th, Tbody,Switch, Icon } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import {FcApproval} from 'react-icons/fc'
import { getCookie } from "../../../utilities";
// Custom components
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  RadioGroup,
  Radio
} from '@chakra-ui/react'

import Banner from "views/admin/billing/components/Banner";
import General from "views/admin/billing/components/General";
import Notifications from "views/admin/billing/components/Notifications";
import Projects from "views/admin/billing/components/Projects";
import Storage from "views/admin/billing/components/Storage";
import Upload from "views/admin/billing/components/Upload";
import ReactToPrint from 'react-to-print';

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import React from "react";
import { useState, useEffect,useRef } from "react";
import { dateFormat } from "utilities";
import { isDefined } from "@chakra-ui/utils";
import { isEmpty } from "lodash";
import Webcam from "react-webcam";
import { Receipt } from "./components/Receipt";

var {dateFormatNormal, toTitleCase, copyJSON} = require('../../../utilities')

var axios = require('axios')
var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

async function getAppointmentData(appointmentData,selectedDate, setAppointmentData) {

  var res = await axios.get(`${REACT_APP_BASE_URL}/getCompletedAppointments?date=${selectedDate}`)
  console.log('app',res.data);
  if(isDefined(res.data.result)){
    setAppointmentData(res.data.result)
  }
  else  setAppointmentData([])
}

//photo
const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "environment"
};

const WebcamCapture = ({setImageSrc}) => (
  
  <Webcam
    audio={false}
    height={720}
    screenshotFormat="image/jpeg"
    width={1280}
    videoConstraints={videoConstraints}
  >
    {({ getScreenshot }) => (
      <Button colorScheme='blue'
        onClick={() => {
          console.log('Clicked image ',0);
          setImageSrc(getScreenshot())
          //console.log(props.props.imageSrc);
        }}
      >
        Capture photo
        
      </Button>


    )}
  </Webcam>
);

//Save transaction
async function saveTransaction(clickedPatient, imageSrc, setClickPatient, setImageSrc,onClose,appointmentData,selectedDate,setAppointmentData ) {
  console.log('Saving transaction ');
  console.log(clickedPatient)
  var res = await axios.post(`${REACT_APP_BASE_URL}/saveTransaction`, {
    appointment_id:clickedPatient.appointment_id, 
    amount:clickedPatient.metadata.fee, 
    mode:clickedPatient.mode, 
    phone:clickedPatient.phone, 
    reference:{image:imageSrc,metadata:clickedPatient.metadata}
  })
  console.log('Save res ', res);
  //refresh
  getAppointmentData(appointmentData,selectedDate,setAppointmentData)
  onClose()
  setImageSrc("")

}
//Modal
function BasicModal(isOpen, onOpen, onClose,clickedPatient, setClickPatient, imageSrc, setImageSrc,appointmentData,selectedDate,setAppointmentData) {
  
  return (
    <>
    
    
      {isEmpty(clickedPatient) ? "" :
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{toTitleCase(clickedPatient.patient_name)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           
          <Text>{`Rs. ` + clickedPatient.metadata.fee + ` paid by `}</Text>
          
          <RadioGroup onChange={(a)=>setClickPatient(copyJSON(clickedPatient,{mode:a}))}>
          <Stack direction="row" spacing={3}>
          <Radio value='cash' >Cash </Radio>
          <Radio value='upi'>UPI </Radio>
          <Radio value='cheque'>Cheque </Radio>
          </Stack>
          </RadioGroup>
          
          {clickedPatient.mode=='upi' ? <WebcamCapture setImageSrc = {setImageSrc} /> : ""}
          </ModalBody>

          <ModalFooter>
           <Card>
          {imageSrc ? <img src={imageSrc}/> : ""}
          </Card>
            <Button colorScheme='blue' isDisabled={!((clickedPatient.mode && !imageSrc && clickedPatient.mode!="upi") || (clickedPatient.mode=='upi' && imageSrc)) ? true : false } //complex condition
            onClick={()=>saveTransaction(clickedPatient, imageSrc, setClickPatient, setImageSrc, onClose, appointmentData,selectedDate,setAppointmentData )}>Paid</Button>
          </ModalFooter>
          
        </ModalContent>
      </Modal> }
    </>
  )
}

export default function Billing() {

  const [dateSelector, setDateSelector] = useState(false)
  const [selectedDate, setSelectedDate] = useState(dateFormat(new Date().toLocaleString("en-US")))
  const [appointmentData, setAppointmentData] = useState({})
  const [clickedPatient, setClickPatient] = useState()
  const [imageSrc, setImageSrc] = useState()
  const [printData, setPrintData] = useState({})
  const [hasAccess, setHasAccess] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const componentRef = useRef(); //print
  var user = getCookie('user');

  useEffect(()=>{
    
    //
    if(user.response.user_role.permissions.includes("billing")) {
      console.log('Doctor view');
      setHasAccess(true)
    }
    else {
      console.log('No doctor access');
      setHasAccess(false)
    }


    getAppointmentData(appointmentData,selectedDate,setAppointmentData)
  },[])


  return (
    hasAccess ? 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    {//Load modal
    BasicModal(isOpen, onOpen, onClose, clickedPatient,setClickPatient, imageSrc, setImageSrc,appointmentData,selectedDate,setAppointmentData)}
      {/* Main Fields */}
      <Stack spacing={3}>
       <Stack spacing={3} direction="row">
       <Input variant="filled" placeholder="Select Date" width="25%" type="date" 
          value={selectedDate}
          onChange={(a)=>{console.log('date', a.target.value);
          setSelectedDate(a.target.value)}}
          focusBorderColor='blue.300'
          autoFocus="true"
           />

        <Button colorScheme={'blue'} width="15%"
        onClick={()=>getAppointmentData(appointmentData,selectedDate,setAppointmentData)}>List</Button>
          
          </Stack>
          <Card>
          <Switch size='lg' marginLeft="2%"/>
          <TableContainer>
  <Table variant='simple'>
    <Thead>
      <Tr>
        <Th>Name</Th>
        <Th>Phone</Th>
        <Th>Token</Th>
        <Th>Doctor</Th>
        <Th>Fee</Th>
        <Th>Payment</Th>
        <Th>For</Th>
        <Th>Actions</Th>
      </Tr>
    </Thead>
    <Tbody>
     
      { appointmentData.length > 0 ? 
          appointmentData.map(a=> <Tr onClick={()=>{
            if(!a.payment) {
            onOpen()
            setClickPatient(a)
            
            } 
          }
            }
            onMouseEnter = {()=>setPrintData(a)}
            >
            <Td>{toTitleCase(a.patient_name)}</Td>
            <Td>{a.phone}</Td>
            <Td>{a.token}</Td>
            <Td>{a.metadata.doctor}</Td>
            <Td>{"Rs. " + a.metadata.fee}</Td>
            <Td>{a.payment ? <Icon as={FcApproval} w='28px' h='28px' mt='4px' />: "No"}</Td>
            <Td>{dateFormatNormal(a.appointmentfor)}</Td>
            <Td> <ReactToPrint
        trigger={() => <div>
          <div style={{ display: "none" }}> <Receipt ref={componentRef} data={printData} user={user.response.username} /></div>
          <Button colorScheme="yellow"  isDisabled={a.payment?false:true}
          >Receipt</Button>
          </div>}
        content={() => componentRef.current}
      />
     </Td>
          </Tr>) : ""

      }
    </Tbody>
  </Table>
</TableContainer>
          </Card>
          
       </Stack>
    </Box> :  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Heading>You do not have access to view this page</Heading>
    </Box>
  );
}
