import Card from "components/card/Card"
import React from "react"
import { Text, Table, TableContainer, Thead, Tr, Th, Tbody, TableCaption, Td, Tfoot
, Box, Stack, Heading
 } from "@chakra-ui/react"
import { dateFormatNormal, toTitleCase } from "utilities";

function formatText(text) {
    console.log('Formatting text');
    if(text.medication)  {
    var meds = (text.medication).split('\n')
    var res = []
    meds.map((a,i)=>{
        console.log('Split char',a.split(','));
        if(a.split(',').length > 1)
        res.push({
            med:a.split(',')[0].trim(),
            dose:a.split(',')[2].trim(),
            sr:i+1,
            frequency:a.split(',')[1].trim()
        })
    })

    
    //console.log(res[0].frequency[0]+" --- "+res[0].frequency[1]+ " --- "+res[0].frequency[2]);
    return res
}
    
}
export class Receipt extends React.Component {
    constructor(props) {
        super(props);
       console.log('Propsppp ', props);
       
      }
    render() {
        var data = this.props.data
        var user = this.props.user
        var meds = formatText(data)
        console.log('Data ', data);
        return ( data.patient_name ?  
            <Box marginLeft="60px" marginTop="200px" width="80%">
           <Stack spacing={3}>
           <Heading size='xl'textAlign="center">Belsare Advanced ENT Center</Heading>  
           <Heading size='lg' textAlign="center">Receipt</Heading>  
           <Text>Date: {dateFormatNormal(data.appointmentfor)} </Text>
           <Text>Receipt No: {data.appointment_id.split('-')[1]} </Text>
           <Stack spacing={3} direction="row">
           <Text>Received with thanks from </Text>
           <Heading size='md'>{toTitleCase(data.patient_name)} </Heading>
           <Text>the sum of Rs. {data.metadata.fee} by {data.mode}.</Text>
           </Stack>
<Heading size='sm'>{data.advice}</Heading>
<Heading size='sm' textAlign="right">{user}</Heading>
</Stack>
    </Box> : ""
        )
    }
}