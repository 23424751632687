// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue ,SimpleGrid, Textarea,
Button} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import { useState, useRef } from "react";
import { isDefined } from "@chakra-ui/utils";
import { setPrintPrescription } from "redux/reducer";
import { useDispatch, useSelector } from "react-redux";

//printing
import ReactToPrint from 'react-to-print';
import { Prescription } from "../../views/patient/details/components/Prescription";


var {dateFormat, dateFormatNormal} = require("../../utilities")


export default function Conversion(props) {

  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const componentRef = useRef(); //print
  const[expand, setExpand] = useState(false)
  const { ...rest } = props;
  var data = props.data;
  //console.log(data);

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p='20px' align='center' direction='column' w='100%' {...rest}
      onClick = {()=>{
        //setting dispatch context
        dispatch(setPrintPrescription({
          patient_name:data.patient_name,
          medication:data.metadata.medication,
          advice:data.metadata.advice,
          follow_up:data.metadata.follow_up,
          tests:data.metadata.tests ? data.metadata.tests : "",
          doctor:state.session.username,
          diagnosis:data.metadata.diagnosis
        }));
        setExpand(!expand)}}
      bg = {data.iscomplete=="true" ? "white" : "tomato"}
      >

      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='8px'>
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
          {dateFormatNormal(data.appointmentfor)} 
        </Text>
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
          {data.metadata.doctor}
        </Text>
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
         
          {data.metadata.complaint?data.metadata.complaint:data.complaint}
        </Text>
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
         
          {`Rs. ${isDefined(data.metadata.fee) ? data.metadata.fee : 0 }`}
        </Text>
      </Flex>

      {expand ? <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >
        <Card >
      <Text mb='8px'>Past History</Text>
      <div>
       <Textarea isReadOnly color={textColor} fontSize='md' fontWeight='600' mt='4px'>
         {data.metadata.past_history}
      </Textarea>
      </div>
      </Card>
      <Card >
      <Text mb='8px'>Examination</Text>
      <div>
       <Textarea isReadOnly color={textColor} fontSize='md' fontWeight='600' mt='4px'>
         {data.metadata.examination}
      </Textarea>
      </div>
      </Card>
  
      <Card >
      <Text mb='8px'>Diagnosis</Text>
      <div>
       <Textarea isReadOnly color={textColor} fontSize='md' fontWeight='600' mt='4px'>
         {data.metadata.diagnosis}
      </Textarea>
      </div>
      </Card>
      <Card >
      <Text mb='8px'>Medication</Text>
      <div>
       <Textarea isReadOnly color={textColor} fontSize='md' fontWeight='600' mt='4px'>
         {data.metadata.medication}
      </Textarea>
      </div>
      </Card>
      <Card >
      <Text mb='8px'>Advice</Text>
      <div>
       <Textarea isReadOnly color={textColor} fontSize='md' fontWeight='600' mt='4px'>
         {data.metadata.advice}
      </Textarea>
      </div>
      </Card>
      <Card >
      <Text mb='8px'>Notes</Text>
      <div>
       <Textarea isReadOnly color={textColor} fontSize='md' fontWeight='600' mt='4px'>
         {data.metadata.notes}
      </Textarea>
      </div>
      </Card>
      <ReactToPrint
        trigger={() => <div>
          <div style={{ display: "none" }}> <Prescription ref={componentRef} data={state.prescription} /></div>
          <Button colorScheme="yellow"> Print Prescription</Button>
          </div>}
        content={() => componentRef.current}
      />
      

       </SimpleGrid> : null }
     
    </Card>
  );
}
