// chakra imports
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React from "react";

// FUNCTIONS
function filterRoutes(routes, link, visible) {
  var _route = []
  if(visible){
  routes.map(a=> {
    if(a.name == link ) _route.push(a) 
  }) } 
  if(!visible) {
    routes.map(a=> {
      if(a.name != link ) _route.push(a) 
    })
  } 
  if(visible==null && link==null) return routes
return _route
}

function SidebarContent(props) {
  const { routes } = props;
  console.log('Content routes ', routes);
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={filterRoutes(routes,"Logout",false)} />
        </Box>
 
      </Stack>

      <Box
        ps='20px'
        pe={{ md: "16px", "2xl": "0px" }}
        mt='60px'
        mb='40px'
        borderRadius='30px'>
           <Links routes={filterRoutes(routes, "Logout", true)} />
        <Text  color= 'gray.500'>{process.env.REACT_APP_BUILD_VERSION}</Text>
      </Box>
   
    </Flex>
  );
}

export default SidebarContent;
