import Card from "components/card/Card"
import React from "react"
import { Text, Table, TableContainer, Thead, Tr, Th, Tbody, TableCaption, Td, Tfoot
, Box, Stack, Heading, Icon
 } from "@chakra-ui/react"
import { dateFormatNormal, toTitleCase } from "utilities";
import rxicon from '../../../../../src/assets/img/icons/rx-icon.ico'

function formatText(text) {
    console.log('Formatting text');
    try{if(text.medication)  {
    var meds = (text.medication).split('\n')
    var res = []
    meds.map((a,i)=>{
        console.log('Split char',a.split(','));
        if(a.split(',').length > 1)
        res.push({
            med:a.split(',')[0].trim(),
            dose:a.split(',')[2].trim(),
            sr:i+1,
            frequency:a.split(',')[1].trim()
        })
    })

    
    //console.log(res[0].frequency[0]+" --- "+res[0].frequency[1]+ " --- "+res[0].frequency[2]);
    return res
} else {
  var meds = true;
  console.log('med map', meds);
}
} catch(e){}

}


export class Prescription extends React.Component {

    constructor(props) {
        super(props);
       console.log('Propsppp ', props);
       
      }
    render() {
      console.log('Printing handle');

        var data = this.props.data
        var meds = formatText(data)
        console.log('Data ', data);
        return ( meds ?  
            <Box marginLeft="60px" marginTop="200px" width="80%">
           <Stack spacing={3}>
          
           <Stack justify='space-between' align='center' direction='row'>
          
           <Heading size='md'>{toTitleCase(data.patient_name)} </Heading>
           <Text>Date: {dateFormatNormal(new Date())} </Text>

          </Stack>
          {data.diagnosis ? <Text><b>Diagnosis:</b> {data.diagnosis} </Text> : ""}
         
           <img src={rxicon} width="5%"></img>
            
           {meds.length ? 
           <TableContainer width="100%">
  <Table variant='simple' borderWidth="2px" >
    {true ? <Thead borderWidth="2px">
      <Tr>
        
        <Th textAlign="center">Medicine</Th>
        <Th textAlign="center">Frequency</Th>
        <Th textAlign="center">Duration</Th>
      </Tr>
    </Thead> : ""}
    <Tbody>
    
     { 
         meds.map(a=><Tr>
             
             <Td textAlign="center">{a.med}</Td>
             <Td textAlign="center">{a.frequency[0]+" --- "+a.frequency[1]+ " --- "+a.frequency[2]}</Td>
             <Td textAlign="center">{a.dose}</Td>

         </Tr>

         ) 
     }
    </Tbody>
    <Tfoot>
      <Tr>
        
        <Th></Th>
        <Th textAlign="center">Morning --- Lunch --- Night</Th>
        <Th></Th>
      </Tr>
    </Tfoot>
  </Table>
</TableContainer> : "" }
{data.tests ? data.tests.split('\n').map((a)=><Text>{a}</Text> ): ""}

<Heading size='sm'>{data.advice}</Heading>
{data.follow_up ? <Heading size='sm'>Follow up on {dateFormatNormal(new Date((new Date()).setDate((new Date()).getDate()+ parseInt(data.follow_up))))}</Heading> : ""}
<Heading size='sm' textAlign="right">{"Dr. "+data.doctor}</Heading>
</Stack>
    </Box> : ""
        )
    }
}