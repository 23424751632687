export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "patient_name",
  },
  {
    Header: "PHONE",
    accessor: "phone",
  },
  {
    Header: "TOKEN",
    accessor: "token",
  },
  {
    Header: "DOCTOR",
    accessor: "doctor",
  },
  {
    Header: "COMPLAINT",
    accessor: "complaint"
  },
  
  {
    Header: "TIME",
    accessor: "time",
  },
  
  {
    Header: "STATUS",
    accessor: "ischecked",
  },
  
  {
    Header: "AT",
    accessor: "checked_at"
  },
  {
    Header: "WAITTIME",
    accessor: "waittime"
  },
  {
    Header: "URGENT",
    accessor: "urgent"
  },
  {
    Header: "VIP",
    accessor: "isvip"
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "patient_name",
  },
  {
    Header: "TOKEN",
    accessor: "token",
  },
  {
    Header: "AGE",
    accessor: "age",
  },
  {
    Header: "GENDER",
    accessor: "gender",
  },
  {
    Header: "COMPLAINT",
    accessor: "complaint",
  },
  {
    Header: "TIME",
    accessor: "time",
  },
  ,
  {
    accessor: "isvip",
  }
];
