export function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  export function dateFormat(date) {
    return new Date(date).toISOString().split('T')[0]
   // return new Date(date).toISOString().replace(/T.*/,'').split('-').reverse().join('-')
}

export function dateFormatNormal(date) {
  //return new Date(date).toISOString().split('T')[0]
 return new Date(date).toISOString().replace(/T.*/,'').split('-').reverse().join('-')
}

//formatAMPM(new Date('2022-10-03T04:20:42.659Z')))

export function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    cvalue = JSON.stringify(cvalue)
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  export function getCookie(cname) {
    let name = cname + "=";
    try{
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
        c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
        return JSON.parse(c.substring(name.length, c.length));
        }
    } } catch(e) {return ""}
    return ""
    }

    export   function copyJSON(obj, value) {
      return {...obj,...value}
    }
    

    export function msToTime(duration) {
      var milliseconds = Math.floor((duration % 1000) / 100),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    
      hours = (hours < 10) ? "0" + hours : hours;
      minutes = (minutes < 10) ? "0" + minutes : minutes;
      seconds = (seconds < 10) ? "0" + seconds : seconds;
    
      return hours + ":" + minutes ;
    }
//module.exports = {toTitleCase,dateFormat, setCookie, getCookie,formatAMPM, dateFormatNormal,copyJSON}