
// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,Button, ButtonGroup,Input ,Stack,InputGroup,InputLeftElement,InputRightElement,
  Radio, RadioGroup, Checkbox, Text,Textarea, Heading, Table, Th, Tr, Td,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure, Thead, Tbody, TableContainer
  
} from "@chakra-ui/react";
// Assets
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Usa from "assets/img/dashboards/usa.png";
import { useHistory } from "react-router-dom";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";

import {PhoneIcon} from '@chakra-ui/icons'
import Card from "components/card/Card";
import HistoryCard from "components/historyCard/HistoryCard"
import { useLocation } from "react-router-dom";

import { useEffect, useState, useRef } from "react";
import { isDefined } from "@chakra-ui/utils";
import { isEmpty, lowerCase } from "lodash";
import {getCookie, setCookie} from '../../../utilities'
import axios from "axios";
import ReactToPrint from 'react-to-print';
import { Prescription } from "../details/components/Prescription";
import { useDispatch, useSelector } from "react-redux";
import {setSession, setTemplate} from '../../../redux/reducer'

var {toTitleCase, dateFormat} = require('../../../utilities')

var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

//function definitions
async function updatePatientPage(setPatient,patient_id, setHistory) {
var res = await axios.get(`${REACT_APP_BASE_URL}/getPatientHistory?patient_id=${patient_id}`)
if(res.data.status=="success") {
  console.log('all patient data');
  console.table(res.data.result)
  setHistory(res.data.result)
  setPatient({
    patient_name:toTitleCase(res.data.result[0].patient_name),
    age:res.data.result[0].age,
    gender:res.data.result[0].gender=='M' ? 'Male' : 'Female',
    token:res.data.result[0].token,
    phone:res.data.result[0].phone,
    address:res.data.result[0].address,
    birthdate:dateFormat(res.data.result[0].birthdate),
    complaint:res.data.result[0].complaint,
    appointment_id:res.data.result[0].appointment_id,
    fee:res.data.result[0].metadata.fee ? res.data.result[0].metadata.fee:process.env.REACT_APP_BASE_FEE, //default fee
    diagnosis:res.data.result[0].metadata.diagnosis ? res.data.result[0].metadata.diagnosis:"",
    examination:res.data.result[0].metadata.examination ? res.data.result[0].metadata.examination:"",
    medication:res.data.result[0].metadata.medication ? res.data.result[0].metadata.medication:" ",
    notes:res.data.result[0].metadata.notes ? res.data.result[0].metadata.notes:"",
    past_history:res.data.result[0].metadata.past_history ? res.data.result[0].metadata.past_history:"",
    advice:res.data.result[0].metadata.advice ? res.data.result[0].metadata.advice:"",
    follow_up:res.data.result[0].metadata.follow_up ? res.data.result[0].metadata.follow_up:"",
    iscomplete:res.data.result[0].iscomplete,
    doctor:res.data.result[0].metadata.doctor
    
  })
  
}

}

async function saveData(patient, setPatient, _history) {
  console.table(patient);
  
  //submit to db
  var res = await axios.post(`${REACT_APP_BASE_URL}/addExamination`,patient)
  console.log('Result of update', res);
  if(res.data.status=="success") {
    alert("Appointment saved successfully")
    
  }
}

function copyTemplate(num,setPatient, patient, state ) {
  console.log('Num');
  var menu = "";
  
  state.templates.med_set.map((a,i)=>{
    if(num==a.id+1)
    menu = a.name.replace(/\\n/g, "\n")
  })

  setPatient({...patient,medication:menu})
  
}

//Modal function
function dialogBox(isOpen, onClose, onOpen, buttonHoverState) {
  console.log('Printing dialog')
  try{
    if(buttonHoverState.length>1) {
  var meds = buttonHoverState.split('\n')
    var res = []
    meds.map((a,i)=>{
        console.log('Split char',a.split(','));
        if(a.split(',').length > 1)
        res.push({
            med:a.split(',')[0].trim(),
            dose:a.split(',')[2].trim(),
            sr:i+1,
            frequency:a.split(',')[1].trim()
        })
    })
  }}
  catch(e) {console.log('Error')}
  return (
    <>
    <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
          <TableContainer width="100%">
  <Table variant='simple' borderWidth="2px" >
    <Thead borderWidth="2px">
      <Tr>
        
        <Th textAlign="center">Medicine</Th>
        <Th textAlign="center">Frequency</Th>
        <Th textAlign="center">Duration</Th>
      </Tr>
    </Thead>
    <Tbody>
    
    { res && res.length>0 ?
         res.map(a=><Tr>
             
             <Td textAlign="center">{a.med}</Td>
             <Td textAlign="center">{a.frequency[0]+" --- "+a.frequency[1]+ " --- "+a.frequency[2]}</Td>
             <Td textAlign="center">{a.dose}</Td>

         </Tr>

         ) : ""
     }
    </Tbody>
  
  </Table>
</TableContainer> 
           </ModalBody>
        </ModalContent>
      </Modal> 
</>
  )
}
export default function UserReports() {

  const componentRef = useRef(); //print
  const state = useSelector(state=>state)
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [buttonHoverState, setButtonHoverState] = useState()

  var _history = useHistory()
  const location = useLocation();
  const id = location.pathname.split("/")
  const patient_id = id[3]
  console.log('In patient details page',id[3]);
  var user = getCookie('user')

  var hs = {
    patient_name:"siddhesh keluskar",
    date: "24-09-2022",
    complaint: "Ear Pain",
    doctor: "Dr. Sai Belsare",
    examination: "Lorem ipsum\n lorem ipsum\n lorem ipsum",
    medication: "T.Loxof 500mg, 101, 5 days\n T.Loxof 500mg, 101, 5 days\n T.Loxof 500mg, 101, 5 days",
    past_history: "Lorem ipsum\nlorem ipsum\norem ipsum",
    diagnosis: "Lorem ipsum\n lorem ipsum\n lorem ipsum",
    advice: "Avoid cold, junk, oily food. No swimming for 1 week. Drink plenty of water",
    notes: "Lorem ipsum\n lorem ipsum\n lorem ipsum",
    fee: process.env.REACT_APP_BASE_FEE,
    follow_up: "1 week",
    complete:false
  }
  
  const [hasAccess, setHasAccess] = useState(false);
  const [history, setHistory] = useState([])
  const [patient, setPatient] = useState({
    patient_name:"",
    age:0,
    gender:"",
    token:"",
    phone:"",
    address:"",
    birthdate:"",
    complaint:"",
    past_history:"",
    examination:"",
    diagnosis:"",
    medication:"",
    advice:"",
    notes:"",
    fee:process.env.REACT_APP_BASE_FEE,
    follow_up:"",
    tests:""


  })

  
  console.log('This is test', new Date((new Date()).setDate((new Date()).getDate()+10)));
  useEffect(async () => {
    // Update the document title using the browser API
    var user = getCookie('user');
    axios.defaults.headers.common['x-access-token'] = user.token

    //set dispatch actions
    dispatch(setSession())
    dispatch(setTemplate())

    //
    if(user.response.user_role.permissions.includes("doctor")) {
      console.log('Doctor view');
      setHasAccess(true)
    }
    else {
      console.log('No doctor access');
      setHasAccess(false)
    }

    updatePatientPage(setPatient,patient_id,setHistory)

  },[]);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    
    // the item selected
    console.log(item)
  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }

function buttonMouseHandler(num) {
    var menu;
    state.templates.med_set.map((a,i)=>{
      if(num==a.id+1)
     menu = a.name.replace(/\\n/g, "\n")
     
    })
    setButtonHoverState(menu);
    onOpen()
}

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
      hasAccess ?
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
     
      {/*<!Form layout>*/}
      {dialogBox(isOpen, onClose,onOpen,buttonHoverState)}
      <Stack spacing={3}>
      <Text fontSize='4xl'>{patient.patient_name}</Text>
      <Stack spacing={3} direction="row">
      <Text fontSize='2xl'>{patient.age} year</Text>
      <Text fontSize='2xl'>{patient.gender}</Text>
      </Stack>
      <Text fontSize="lg">
        Token number: <b>{patient.token}, </b>
        Phone: <b>{patient.phone}</b>, 
        DoB: <b>{patient.birthdate}</b>, 
        Address: <b>{patient.address}</b></Text>
      
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >

      <Card>
      <Text mb='8px'>Complaints</Text>
      <Textarea value = {patient.complaint}
       onChange={(a)=>setPatient({
         ...patient,
         complaint:a.target.value
       })}> </Textarea>
      </Card>


      <Card >
      <Text mb='8px'>Past History</Text>
      <div>
        <ReactSearchAutocomplete
            items={state.templates.past_history}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={(a)=>setPatient({
              ...patient,
              //past_history:(isDefined(patient.past_history) ? patient.past_history + "\n" :"") + a.name
              past_history:((patient.past_history.length>3 && patient.past_history.charCodeAt(patient.past_history.length-1)!= 10)? patient.past_history+"\n":patient.past_history) + a.name
            })}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            styling={{
              borderRadius: "7px",
              boxShadow: "0px",
              zIndex: '99',
              
            }}
            placeholder={"Past History"}
            showIcon = {false}
          />
  
  <Textarea value = {patient.past_history}
       onChange={(a)=>setPatient({
         ...patient,
         past_history:a.target.value
       })}> </Textarea>

      </div>
      </Card>
      <Card >
      <Text mb='8px'>Examination</Text>
      <div>
        <ReactSearchAutocomplete
            items={state.templates.examination}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={(a)=>setPatient({
              ...patient,
             // examination:(isDefined(patient.examination) ? patient.examination+"\n":"") + a.name
             examination:((patient.examination.length>3 && patient.examination.charCodeAt(patient.examination.length-1)!= 10)? patient.examination+"\n":patient.examination) + a.name
            })}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            styling={{
              borderRadius: "7px",
              boxShadow: "0px",
              zIndex: '99',
              
            }}
            placeholder={"Examination"}
            showIcon = {false}
          />
  
  <Textarea value = {patient.examination}
       onChange={(a)=>setPatient({
         ...patient,
         examination:a.target.value
       })}> </Textarea>
      </div>
      </Card>

      <Card >
      <Text mb='8px'>Diagnosis</Text>
      <div>
        <ReactSearchAutocomplete
            items={state.templates.diagnosis}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={(a)=>setPatient({
              ...patient,
             // diagnosis:(isDefined(patient.diagnosis) ? patient.diagnosis+"\n":"") + a.name
             diagnosis:((patient.diagnosis.length>3 && patient.diagnosis.charCodeAt(patient.diagnosis.length-1)!= 10)? patient.diagnosis+"\n":patient.diagnosis) + a.name
            })}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            styling={{
              borderRadius: "7px",
              boxShadow: "0px",
              zIndex: '99',
              
            }}
            placeholder={"Diagnosis"}
            showIcon = {false}
          />
  
  <Textarea value = {patient.diagnosis}
       onChange={(a)=>setPatient({
         ...patient,
         diagnosis:a.target.value
       })}> </Textarea>
      </div>
      </Card>
      
      <Card >
        <Stack spacing={3} direction="row">
      <Text mb='8px' >Medication</Text>
      
      <Button style={{zIndex:8000}} colorScheme="red" onClick={()=>copyTemplate(1, setPatient, patient , state)}
      onMouseEnter={()=>buttonMouseHandler(1)}
      onMouseOut={()=>onClose()}>1</Button>
      <Button style={{zIndex:8000}} colorScheme="red" onClick={()=>copyTemplate(2, setPatient, patient, state)}
       onMouseEnter={()=>buttonMouseHandler(2)}
       onMouseOut={()=>onClose()}>2</Button>
      <Button style={{zIndex:8000}} colorScheme="red" onClick={()=>copyTemplate(3, setPatient, patient, state)}
       onMouseEnter={()=>buttonMouseHandler(3)}
       onMouseOut={()=>onClose()}>3</Button>
      <Button style={{zIndex:8000}} colorScheme="red" onClick={()=>copyTemplate(4, setPatient, patient, state)}
       onMouseEnter={()=>buttonMouseHandler(4)}
       onMouseOut={()=>onClose()}>4</Button>
      <Button style={{zIndex:8000}} colorScheme="red" onClick={()=>copyTemplate(5, setPatient, patient, state)}
       onMouseEnter={()=>buttonMouseHandler(5)}
       onMouseOut={()=>onClose()}>5</Button>
      <Button style={{zIndex:8000}} colorScheme="red" onClick={()=>copyTemplate(6, setPatient, patient, state)}
       onMouseEnter={()=>buttonMouseHandler(6)}
       onMouseOut={()=>onClose()}>6</Button>
      <Button style={{zIndex:8000}} colorScheme="red" onClick={()=>copyTemplate(7, setPatient, patient, state)}
       onMouseEnter={()=>buttonMouseHandler(7)}
       onMouseOut={()=>onClose()}>7</Button>
      <Button style={{zIndex:8000}} colorScheme="red" onClick={()=>copyTemplate(8, setPatient, patient, state)}
       onMouseEnter={()=>buttonMouseHandler(8)}
       onMouseOut={()=>onClose()}>8</Button>
      <Button style={{zIndex:8000}} colorScheme="red" onClick={()=>copyTemplate(90, setPatient, patient, state)}>X</Button>
      
      </Stack>
      <div>
        <ReactSearchAutocomplete
            items={state.templates.medication}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={(a)=>setPatient({
              ...patient,
              //medication:(isDefined(patient.medication) ? patient.medication+"\n":"")  + a.name
              medication:((patient.medication.length>3 && patient.medication.charCodeAt(patient.medication.length-1)!= 10)? patient.medication+"\n":patient.medication) + a.name
            })}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            styling={{
              borderRadius: "7px",
              boxShadow: "0px",
              zIndex: '99',
              
            }}
            placeholder={"Medication"}
            showIcon = {false}
            
            
          />
  
  <Textarea value = {patient.medication}
       onChange={(a)=>setPatient({
         ...patient,
         medication:a.target.value
       })}> </Textarea>
      </div>
      </Card>
      
      
      <Card >
      <Text mb='8px'>Advice</Text>
      <div>
        <ReactSearchAutocomplete
            items={state.templates.advice}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={(a)=>{setPatient({
              ...patient,
              advice:((patient.advice.length>3 && patient.advice.charCodeAt(patient.advice.length-1)!= 10)? patient.advice+"\n":patient.advice) + a.name
            });
            
          }}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            styling={{
              borderRadius: "7px",
              boxShadow: "0px",
              zIndex: '99',
              
            }}
            placeholder={"Advice"}
            showIcon = {true}
            
            
            
            
          />
  
  <Textarea value = {patient.advice}
       onChange={(a)=>setPatient({
         ...patient,
         advice:a.target.value
       })}> </Textarea>
      </div>
      </Card>
      
      <Card>
      <Text mb='8px'>Notes (Hidden from patient)</Text>
      <Textarea value = {patient.notes}
       onChange={(a)=>setPatient({
         ...patient,
         notes:a.target.value
       })}> </Textarea>
      </Card>

      <Card >
      <Text mb='8px'>Investigation</Text>
      <div>
        <ReactSearchAutocomplete
            items={state.templates.tests}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={(a)=>setPatient({
              ...patient,
              tests:(isDefined(patient.tests) ? patient.tests+"\n":"") + a.name
            })}
            onFocus={handleOnFocus}
            autoFocus
            formatResult={formatResult}
            styling={{
              borderRadius: "7px",
              boxShadow: "0px",
              zIndex: '99',
              
            }}
            placeholder={"Investigations"}
            showIcon = {false}
            
            
          />
  
  <Textarea value = {patient.tests}
       onChange={(a)=>setPatient({
         ...patient,
         tests:a.target.value
       })}> </Textarea>
      </div>
      </Card>

</SimpleGrid>

    <Stack spacing={3} direction="row">
      <Text mb='8px'>Fee</Text> <Input width="20%" value={patient.fee}
      onClick={(a)=>setPatient({
        ...patient,
        fee:""
      })}
      onChange={(a)=>setPatient({
        ...patient,
        fee:a.target.value
      })}></Input>
      <Text mb='8px'>Follow up Days</Text>  
      <Input width="25%" placeholder='Select value' 
      onChange={(a)=>setPatient({...patient,follow_up:a.target.value})}>
</Input>
    </Stack>
    <Stack direction="row">
      <Button onClick={()=>saveData(patient, setPatient,_history)}
        colorScheme="blue">Save Details</Button>
      <div>
      <ReactToPrint
        trigger={() => <div>
          <div style={{ display: "none" }}> <Prescription ref={componentRef} data={patient} /></div>
          <Button colorScheme="yellow"> Print Prescription</Button>
          </div>}
        content={() => componentRef.current}
      />
     
    </div>
    <Button onClick={()=> setPatient({
      ...patient,
      complaint:"",
      past_history:"",
      examination:"",
      diagnosis:"",
      medication:" ",
      advice:"",
      notes:"",
      follow_up:"",
      tests:""
    }) } colorScheme="red">Clear</Button>
    <Button onClick={()=>_history.push('/admin/doctor') } colorScheme="red">Close</Button>
    
    </Stack>

    <Heading>Past Visits</Heading>
      
      {history.map((a,i)=> { 
              if(i>0)
              return <HistoryCard data={a}></HistoryCard>  } )}
      

      </Stack>
    </Box>
    : <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <Heading>You do not have access to view this page</Heading>
  </Box>
  );
}


//complaints should have autofill
//address should have autofill
//token number is for every patient - validity 1 day, 4am
//token response system
//auto gen message before 1 hour

//chief complaints
//Examination
//complaint, past history, allergy, examination, diagnosis, medication, advice, notes
//Past history. allergy
//Print: prescription document. 
//diagnosis, medication, advice (advice & medication - instructions in marathi) follow up date
// 1 -- x -- 1 10 Days After food
// 0.5 -- x -- x 2 days
// Examination, diagnosis,  medication, Date - click for all details. close then only date


//add medication templates 8 to 10 combination of drugs
/*{history.map((a)=>
  <Text>Date:
    Complaint:
    Doctor:
    Examination:
    Medication:
    Past History:
    Diagnosis:
    Advice:
    Notes:
    Fee:
    Follow up:
  </Text>*/