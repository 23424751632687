import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect, BrowserRouter, Routes } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import PatientLayout from "layouts/patient"
import DoctorLayout from "layouts/doctor"


import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";

import { CookiesProvider } from 'react-cookie';
import {store} from './redux/store'
import {Provider} from 'react-redux'
import axios from "axios"
import { getCookie } from "utilities";

import { registerLicense } from '@syncfusion/ej2-base';


var user = getCookie('user');
//registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VhXn5ddHZRQmdVVUQ=');
//registerLicense('MzAyMzA2NkAzMjMwMmUzMzJlMzBFdkM3VjVhdytFWFVmNmZiZFRqNWNWd0lnbE9meGFkUllIWHE3aVVTVFhBPQ==;MzAyMzA2N0AzMjMwMmUzMzJlMzBiSG1mNEFEVy9iM002SlltajVaRkxGbE1FM2dyZklVaHlzVDlJRkU5Z25JPQ==;Mgo+DSMBaFt/QHNqVVhlXlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQFljSH5bdkBjWX5bd31VRg==;Mgo+DSMBPh8sVXJ0S0V+XE9BdFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3pTd0VrWXpecHVQTmBZVw==;ORg4AjUWIQA/Gnt2VVhjQlFac11JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXdkRjUX9bcHRUQ2hcVEI=;NRAiBiAaIQQuGjN/V0Z+X09EaFpAVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RckViWXdedXRVR2VUU0V0;MzAyMzA3MkAzMjMwMmUzMzJlMzBsOStNNnlxdjJFRFBrMnltaTBZUERZWFpKSGV6d3ppVWI3TmlSV0NIV2dJPQ==;MzAyMzA3M0AzMjMwMmUzMzJlMzBlcEhMaVVONkFjd0pRTFJSL3VOZEtSSGtSSFUxVmx2am1RdjZuOXd4VTJ3PQ==;Mgo+DSMBMAY9C3t2VVhjQlFac11JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXdkRjUX9bcHRUQ2hUVU0=;MzAyMzA3NUAzMjMwMmUzMzJlMzBXUjA4THJqcm5wbXdSa3ZCOGNEMjgvTm44NUx5TnIwRjg4L2VEVHpDVEdrPQ==;MzAyMzA3NkAzMjMwMmUzMzJlMzBGemQyM0pvV1RKcDhqOU14dzdrcDNyNi9Cd1h6d05ENklPU1lzWXVxbk9JPQ==;MzAyMzA3N0AzMjMwMmUzMzJlMzBsOStNNnlxdjJFRFBrMnltaTBZUERZWFpKSGV6d3ppVWI3TmlSV0NIV2dJPQ==');
//registerLicense('MzA3MzQ2NUAzMjMwMmUzMzJlMzBRY3hra2JyNmlrSWxsdXdrTUhFU3FENmNGQzBBY3EwbndpRFJpaG1wQlU4PQ==;MzA3MzQ2NkAzMjMwMmUzMzJlMzBNMVpKM2FBVDlFR2Z4OUQzVHl4N3BBdU5lTFFtUEpnd1N2K1M4TzVoZFFnPQ==;Mgo+DSMBaFt/QHNqVVhlXlpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQFljSH1TdkxhX3pZeHJVRQ==;Mgo+DSMBPh8sVXJ0S0V+XE9BdFRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3pTd0ZjWXZcdnFSTmddUg==;ORg4AjUWIQA/Gnt2VVhjQlFac11JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXdkRgWX9XcnJQQWhUUkc=;NRAiBiAaIQQuGjN/V0Z+X09EaFpAVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RckViWn9eeXZTQ2dUWEN1;MzA3MzQ3MUAzMjMwMmUzMzJlMzBKVHZZaUVjZXNuelM2dVBZZUFzM1VlejUvNTJzZ0dDUWM1N3NzcU03TzhVPQ==;MzA3MzQ3MkAzMjMwMmUzMzJlMzBBQkhkZmp6WE1EU3dreFd1YWtpVXBiY3RCbWxmTjVmbG9zM3Z6RmNpVmhJPQ==;Mgo+DSMBMAY9C3t2VVhjQlFac11JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXdkRgWX9XcnJQQWlaVEY=;MzA3MzQ3NEAzMjMwMmUzMzJlMzBoR0tDSUEzQVhJalhIRHRhZklMc1VtZmxzd1FxajZaSUordGp0ZTVRU3pNPQ==;MzA3MzQ3NUAzMjMwMmUzMzJlMzBvaWJlWjVkdlRLRE45VS9DcnJRb3lJalNGQXJnRmpaQ1dHWkNnK0lKNzR3PQ==;MzA3MzQ3NkAzMjMwMmUzMzJlMzBKVHZZaUVjZXNuelM2dVBZZUFzM1VlejUvNTJzZ0dDUWM1N3NzcU03TzhVPQ==');
registerLicense('MzA3MzUwMEAzMjMwMmUzNDJlMzBCREFxc3c1YTB4NlBxcGFCUCtjbml0a1ZCRUd0aVduUXY4RUhTaHhzWitJPQ==;MzA3MzUwMUAzMjMwMmUzNDJlMzBRQ3JVRDdkSWFJanRxQWhKcStIRXBBQjRDSlB1MWRTeEdBcGRicWdVNGlJPQ==;Mgo+DSMBaFt/QHRqVVhlWFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQFljSH1TdkxgXH1ac3FRRA==;Mgo+DSMBPh8sVXJ0S0J+XE9BclRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3pTd0ZjWXZddXZRRWZVWQ==;ORg4AjUWIQA/Gnt2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXdkRgWX9Xc3FXQmNUVEA=;NRAiBiAaIQQuGjN/V0Z+WE9EaFpGVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RckViWn9eeXdQRGRYUUV2;MzA3MzUwNkAzMjMwMmUzNDJlMzBmNGlna0dlYVJvL2ZzNG50YWRJUk5iUDZHekx4eGlmMFZRWkh2Q3RGdzZVPQ==;MzA3MzUwN0AzMjMwMmUzNDJlMzBqT2dmd2UrS21GVFR1ak8vdkdtYXQxdWdWdmJydTExVGtwK2s1dXpXWG13PQ==;Mgo+DSMBMAY9C3t2VVhkQlFac1tJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxXdkRgWX9Xc3FXQmRaVUI=;MzA3MzUwOUAzMjMwMmUzNDJlMzBZazlYZFQ4akJ4OWFaaWVPeURWTWFqbVk2aHdjU1doYUZnc0VKcks4aE80PQ==;MzA3MzUxMEAzMjMwMmUzNDJlMzBEai9IMWp3M2w5U0IxYjJVNmo3dmJEOTJSZWJhM1pjczErQWg1T2pVYWxRPQ==;MzA3MzUxMUAzMjMwMmUzNDJlMzBmNGlna0dlYVJvL2ZzNG50YWRJUk5iUDZHekx4eGlmMFZRWkh2Q3RGdzZVPQ==')
if(user) axios.defaults.headers.common['x-access-token'] = user.token

//Debug disable
if(process.env.REACT_APP_ENV!='dev') {
console.log = () => {}
console.table = () => {}

  console.error = () => {}
  console.debug = () => {}
  console.warn=()=> {}
}


ReactDOM.render(
  <ChakraProvider theme={theme}>
    <CookiesProvider>
    <Provider store={store} >
    <React.StrictMode>
      <ThemeEditorProvider>
        <HashRouter>
          <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Route path={`/patient`} component={PatientLayout} />
            <Route path={`/doctor`} component={DoctorLayout} />
            <Redirect from='/' to='/admin' />
          </Switch>
        </HashRouter>

   
      </ThemeEditorProvider>
    </React.StrictMode>
    </Provider>
    </CookiesProvider>
  </ChakraProvider>,
  document.getElementById("root")
);

//<Redirect from='/' to='/admin' />