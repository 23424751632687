
import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import {getCookie, setCookie} from '../../../utilities'
import io from 'socket.io-client';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  TableContainer,
  Tr, Td, Table, Tbody, Image, HStack
} from "@chakra-ui/react";
import Card from "../../../components/card/Card.js";

// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/819.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";
import { useHistory } from "react-router-dom";
import img from "assets/img/layout/BelsareENT.png"
import {toTitleCase,formatAMPM} from '../../../utilities'
import { FcPlus, FcMinus } from "react-icons/fc";

const QUEUE_SIZE = 5
var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
var REACT_APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL
const socket = io(REACT_APP_SOCKET_URL);

async function publish(stats, queue, nextQueue, list)
{
  //console.log('Stats', stats);
  //console.log('New',[...queue,...nextQueue,...list]);

  //detect change in posiitons
  var cng = []
  var _cng = [...queue,...nextQueue,...list]
  _cng.map((a,i)=>{
    if(a!=stats[i]) {
      cng.push(a)
    }
  })

  console.log(cng);
  //swap ranks
  var ranks = []
  cng.map(a=>ranks.push(a.rank))
  console.log('Ranks', ranks);
  var new_ranks = ranks.sort()
  console.log('Ranks', new_ranks);
  new_ranks.map((a,i)=>{cng[i].new_rank = a})
  console.log('New array', cng);
  //fire query
  var res = await axios.post(`${REACT_APP_BASE_URL}/admin/updateRanks`,cng)
  console.log('as', res.data);
}

async function upRank(a, stats, setStats, setQueue, setList,setNext)
//upRank(a,nextQueue,list, setNext, setList)}
{
  console.log('Rank',a );
 
  //fire query
  var res = await axios.get(`${REACT_APP_BASE_URL}/checkinPriority?appointment_id=${a.appointment_id}&token=${a.token}`)
  refresh(stats, setStats, setQueue, setList,setNext)
  // console.log('as', res.data);
}

async function addToken(pos,nextQueue,list, setNext, setList) {
//console.log('Adding ', pos);
//Add to next Queue
var tmp = [...nextQueue]
tmp.pop()
tmp.push(pos)

//first add to queue
setNext([pos,...nextQueue])
//remove from list
tmp = list
setList([...tmp.filter((a)=>{return a!=pos})])

}

async function remToken(pos,nextQueue,list, setNext, setList) {
  //console.log('Adding ', pos);
  //Add to next Queue
  var tmp = [...nextQueue]
  tmp.pop()
  tmp.push(pos)
  
  //first add to queue
  setList([pos,...list])
  //remove from list
  tmp = nextQueue
  setNext([...tmp.filter((a)=>{return a!=pos})])
  
  }

async function refresh(stats, setStats, setQueue, setList, setNext) {
  var res = await axios.get(`${REACT_APP_BASE_URL}/tokenDetails`)
 
  if(res.data.status=="fail"){
    console.log('Login failed');
 
} else {
  setStats(res.data.result)
  console.log('Result', res.data.result);

  //quwuw and list
  console.log('slicesdcsdc',);
  setQueue(res.data.result.slice(0,QUEUE_SIZE+1))   //1 to 4
  setNext(res.data.result.slice(QUEUE_SIZE+1, 2*QUEUE_SIZE+1)) //4,6
  setList(res.data.result.slice(2*QUEUE_SIZE+1)) //6 onwards

  //console.log("Stats",res.data.result.slice(30,90));
}
  
}

function Queue() {
  // Chakra color mode
  const [stats, setStats] = useState([])
  const [time, setTime] = useState(new Date())
  const history = useHistory()
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }


  );

  const bg = useColorModeValue("white", "#1B254B");
  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "none"
  );
  
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [queue, setQueue] = useState([])
  const [list, setList] = useState([])
  const [nextQueue, setNext] = useState([])

  useEffect(async () => {
    // Update the document title using the browser API

    var user = getCookie('user');

    //
    refresh(stats, setStats,setQueue, setList, setNext)

    socket.on('queue_update', (a) => {
      refresh(stats, setStats, setQueue, setList,setNext)
      console.log('queue_update',a);
    });

    //setInterval(()=> setTime(new Date()),60000)

  },[]);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (

    
   <Box py='80px'>  
   <Stack spacing={4}>
    <Card  boxShadow={shadow} bm='0'>
      <Heading size ={'lg'}>Display Token</Heading>
    <Table>
        <Tbody>
        
          {queue.map((a,i)=> { if (true) return <Tr>
          <Td><Heading textAlign={'center'} size='sm' >{i + 1}</Heading></Td>
          <Td><Heading textAlign={'center'} size='sm' >{toTitleCase(a.patient_name) + "⇨ " + a.doctor}</Heading></Td>
          <Td><Heading textAlign={'center'} size='sm' >{ (a.wait_time.hours ?  + a.wait_time.hours + ' hr ' : "")  + (a.wait_time.minutes ? a.wait_time.minutes + ' mins' : "")}</Heading></Td>
          <Td><Heading textAlign={'center'} size='sm' >{a.token} </Heading></Td>
         
         
          
        </Tr>
          })}
        </Tbody>
      </Table>
      </Card>

      <Card  boxShadow={shadow} bm='0' bg={ nextQueue.length!=QUEUE_SIZE ? 'pink.100' : "white"}>
        <Stack direction={"row"} spacing="70%">
      <Heading size ={'lg'}>Next Token</Heading>
     
      </Stack>
    <Table>
        <Tbody>
        
          {nextQueue.map((a,i)=> {if(true) return <Tr>
            <Td><Heading textAlign={'center'} size='sm' >{i + 7 } </Heading></Td>
          <Td><Heading textAlign={'center'} size='sm' 
           onClick={()=>upRank(a,stats, setStats, setQueue, setList,setNext)}
           _hover={{ cursor: "pointer" }}
           >{toTitleCase(a.patient_name)+ "⇨ " + a.doctor}</Heading></Td>
          <Td><Heading textAlign={'center'} size='sm' >{ (a.wait_time.hours ?  + a.wait_time.hours + ' hr ' : "")  + (a.wait_time.minutes ? a.wait_time.minutes + ' mins' : "")}</Heading></Td>
          <Td><Heading textAlign={'center'} size='sm' >{a.token} </Heading></Td>
         
          
        </Tr>
          })}
        </Tbody>
      </Table>
      </Card>

      <Card  boxShadow={shadow} bm='0'>
      <Heading size ={'lg'}>List Token</Heading>
    <Table>
        <Tbody>
        
          {list.map((a,i)=> {if(true) return <Tr>
            <Td><Heading textAlign={'center'} size='sm' >{i + 12 } </Heading></Td>
          <Td ><Heading textAlign={'center'} size='sm'  onClick={()=>upRank(a, stats, setStats, setQueue, setList,setNext)}
           _hover={{ cursor: "pointer" }} >
             {toTitleCase(a.patient_name)+ "⇨ " + a.doctor}</Heading></Td>
             <Td><Heading textAlign={'center'} size='sm' >{ (a.wait_time.hours ?  + a.wait_time.hours + ' hr ' : "")  + (a.wait_time.minutes ? a.wait_time.minutes + ' mins' : "")}</Heading></Td>
          <Td><Heading textAlign={'center'} size='sm' >{a.token} </Heading></Td>
          
          
        </Tr>
          })}
        </Tbody>
      </Table>
      </Card>
      </Stack>
    </Box>
    
  );
}

export default Queue;

//
/*<Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>

    */

    /* 8th Nov

import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import {getCookie, setCookie} from '../../../utilities'
import io from 'socket.io-client';
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  TableContainer,
  Tr, Td, Table, Tbody, Image, HStack
} from "@chakra-ui/react";
import Card from "../../../components/card/Card.js";

// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/819.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";
import { useHistory } from "react-router-dom";
import img from "assets/img/layout/BelsareENT.png"
import {toTitleCase,formatAMPM} from '../../../utilities'
import { FcPlus, FcMinus } from "react-icons/fc";

const QUEUE_SIZE = 5
var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
var REACT_APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL
const socket = io(REACT_APP_SOCKET_URL);

async function publish(stats, queue, nextQueue, list)
{
  //console.log('Stats', stats);
  //console.log('New',[...queue,...nextQueue,...list]);

  //detect change in posiitons
  var cng = []
  var _cng = [...queue,...nextQueue,...list]
  _cng.map((a,i)=>{
    if(a!=stats[i]) {
      cng.push(a)
    }
  })

  console.log(cng);
  //swap ranks
  var ranks = []
  cng.map(a=>ranks.push(a.rank))
  console.log('Ranks', ranks);
  var new_ranks = ranks.sort()
  console.log('Ranks', new_ranks);
  new_ranks.map((a,i)=>{cng[i].new_rank = a})
  console.log('New array', cng);
  //fire query
  var res = await axios.post(`${REACT_APP_BASE_URL}/admin/updateRanks`,cng)
  console.log('as', res.data);
}

async function upRank(stats,a,nextQueue,list, setNext, setList)
//upRank(a,nextQueue,list, setNext, setList)}
{
  console.log('Rank', stats);
  var rr = [stats[0],a]
  stats.map((j,i)=>{
    if(i>0 && j.rank != a.rank) rr.push(j)
  })
  console.log(rr)
  //fire query
  //var res = await axios.post(`${REACT_APP_BASE_URL}/admin/updateRanks`,cng)
 // console.log('as', res.data);
}

async function addToken(pos,nextQueue,list, setNext, setList) {
//console.log('Adding ', pos);
//Add to next Queue
var tmp = [...nextQueue]
tmp.pop()
tmp.push(pos)

//first add to queue
setNext([pos,...nextQueue])
//remove from list
tmp = list
setList([...tmp.filter((a)=>{return a!=pos})])

}

async function remToken(pos,nextQueue,list, setNext, setList) {
  //console.log('Adding ', pos);
  //Add to next Queue
  var tmp = [...nextQueue]
  tmp.pop()
  tmp.push(pos)
  
  //first add to queue
  setList([pos,...list])
  //remove from list
  tmp = nextQueue
  setNext([...tmp.filter((a)=>{return a!=pos})])
  
  }

async function refresh(stats, setStats, setQueue, setList, setNext) {
  var res = await axios.get(`${REACT_APP_BASE_URL}/tokenDetails`)
 
  if(res.data.status=="fail"){
    console.log('Login failed');
 
} else {
  setStats(res.data.result)
  console.log('Result', res.data.result);

  //quwuw and list
  console.log('slicesdcsdc',);
  setQueue(res.data.result.slice(0,QUEUE_SIZE+1))   //1 to 4
  setNext(res.data.result.slice(QUEUE_SIZE+1, 2*QUEUE_SIZE+1)) //4,6
  setList(res.data.result.slice(2*QUEUE_SIZE+1)) //6 onwards

  //console.log("Stats",res.data.result.slice(30,90));
}
  
}

function Queue() {
  // Chakra color mode
  const [stats, setStats] = useState([])
  const [time, setTime] = useState(new Date())
  const history = useHistory()
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }


  );

  const bg = useColorModeValue("white", "#1B254B");
  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "none"
  );
  
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [queue, setQueue] = useState([])
  const [list, setList] = useState([])
  const [nextQueue, setNext] = useState([])

  useEffect(async () => {
    // Update the document title using the browser API

    var user = getCookie('user');

    //
    refresh(stats, setStats,setQueue, setList, setNext)

    socket.on('queue_update', (a) => {
      refresh(stats, setStats, setQueue, setList,setNext)
      console.log('queue_update',a);
    });

    //setInterval(()=> setTime(new Date()),60000)

  },[]);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (

    
   <Box py='80px'>  
   <Stack spacing={4}>
    <Card  boxShadow={shadow} bm='0'>
      <Heading size ={'lg'}>Display Token</Heading>
    <Table>
        <Tbody>
        
          {queue.map((a,i)=> { if (true) return <Tr>
          <Td><Heading textAlign={'center'} size='sm' >{toTitleCase(a.patient_name) + "⇨ " + a.doctor}</Heading></Td>
          <Td><Heading textAlign={'center'} size='sm' >{a.token} </Heading></Td>
         
         
          
        </Tr>
          })}
        </Tbody>
      </Table>
      </Card>

      <Card  boxShadow={shadow} bm='0' bg={ nextQueue.length!=QUEUE_SIZE ? 'pink.100' : "white"}>
        <Stack direction={"row"} spacing="70%">
      <Heading size ={'lg'}>Next Token</Heading>
      <Button colorScheme={'blue'} isDisabled = {nextQueue.length!=QUEUE_SIZE}
      onClick={()=>publish(stats, queue, nextQueue, list)}>Publish</Button>
      </Stack>
    <Table>
        <Tbody>
        
          {nextQueue.map((a,i)=> {if(true) return <Tr>
          <Td><Heading textAlign={'center'} size='sm' 
           onClick={()=>upRank(stats,a,nextQueue,list, setNext, setList)}
           _hover={{ cursor: "pointer" }}
           >{toTitleCase(a.patient_name)+ "⇨ " + a.doctor}</Heading></Td>
          <Td><Heading textAlign={'center'} size='sm' >{a.token} </Heading></Td>
          <Td><Icon as={FcMinus} width='20px' height='20px' color='inherit' 
          onClick={()=>remToken(a,nextQueue,list, setNext, setList)}
            _hover={{ cursor: "pointer" }}
          /></Td>
          
        </Tr>
          })}
        </Tbody>
      </Table>
      </Card>

      <Card  boxShadow={shadow} bm='0'>
      <Heading size ={'lg'}>List Token</Heading>
    <Table>
        <Tbody>
        
          {list.map((a,i)=> {if(true) return <Tr>
          <Td><Heading textAlign={'center'} size='sm' >{toTitleCase(a.patient_name)+ "⇨ " + a.doctor}</Heading></Td>
          <Td><Heading textAlign={'center'} size='sm' >{a.token} </Heading></Td>
          <Td><Icon as={FcPlus} width='20px' height='20px' color='inherit'
            onClick={()=>addToken(a,nextQueue,list, setNext, setList)} 
            _hover={{ cursor: "pointer" }}/></Td>
          
        </Tr>
          })}
        </Tbody>
      </Table>
      </Card>
      </Stack>
    </Box>
    
  );
}

export default Queue;

//
/*<Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>

    */