
// Chakra imports
import { Box, Grid,
Heading, Stack, Button, Input,
TableContainer, Table, Tr, Td, Thead, Tfoot,
TableCaption, Th, Tbody,Switch, Icon } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import {FcApproval} from 'react-icons/fc'
import { getCookie } from "../../../utilities";

//IPD imports
//import { loadCultureFiles } from '../common/culture-loader';
import { DatePicker, ChangeEventArgs } from '@syncfusion/ej2-calendars';
import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import "../../../App.css"
//import * as dataSource from './datasource.json';
import { extend, createElement } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { TextBox } from '@syncfusion/ej2-inputs'


// Custom components
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  RadioGroup,
  Radio
} from '@chakra-ui/react'

import Banner from "views/admin/billing/components/Banner";
import General from "views/admin/billing/components/General";
import Notifications from "views/admin/billing/components/Notifications";
import Projects from "views/admin/billing/components/Projects";
import Storage from "views/admin/billing/components/Storage";
import Upload from "views/admin/billing/components/Upload";
import ReactToPrint from 'react-to-print';

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import React from "react";
import { useState, useEffect,useRef } from "react";
import { dateFormat } from "utilities";
import { isDefined } from "@chakra-ui/utils";
import { isEmpty } from "lodash";
import Webcam from "react-webcam";
import { Receipt } from "./components/Receipt";

var {dateFormatNormal, toTitleCase, copyJSON} = require('../../../utilities')

var axios = require('axios')
var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

//ipdEvent
async function actionEvent(event) {

  var res = await axios.post(`${REACT_APP_BASE_URL}/users/ipdEvent`, {
    eventType:event.requestType, ...event.data[0]})
  console.log('app actionexecuted',res.data);
}

async function getEventData(monthSelector, setData) {

  var res = await axios.post(`${REACT_APP_BASE_URL}/users/getIpdEvents`, {
     month:monthSelector
  })
  if(isDefined(res.data.result)){
    setData(res.data.result)
  }
  else  setData([])
}


async function getAppointmentData(appointmentData,selectedDate, setAppointmentData) {

  var res = await axios.get(`${REACT_APP_BASE_URL}/getCompletedAppointments?date=${selectedDate}`)
  console.log('app',res.data);
  if(isDefined(res.data.result)){
    setAppointmentData(res.data.result)
  }
  else  setAppointmentData([])
}

//photo
const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "environment"
};

const WebcamCapture = ({setImageSrc}) => (
  
  <Webcam
    audio={false}
    height={720}
    screenshotFormat="image/jpeg"
    width={1280}
    videoConstraints={videoConstraints}
  >
    {({ getScreenshot }) => (
      <Button colorScheme='blue'
        onClick={() => {
          console.log('Clicked image ',0);
          setImageSrc(getScreenshot())
          //console.log(props.props.imageSrc);
        }}
      >
        Capture photo
        
      </Button>


    )}
  </Webcam>
);

//Save transaction
async function saveTransaction(clickedPatient, imageSrc, setClickPatient, setImageSrc,onClose,appointmentData,selectedDate,setAppointmentData ) {
  console.log('Saving transaction ');
  console.log(clickedPatient)
  var res = await axios.post(`${REACT_APP_BASE_URL}/saveTransaction`, {
    appointment_id:clickedPatient.appointment_id, 
    amount:clickedPatient.metadata.fee, 
    mode:clickedPatient.mode, 
    phone:clickedPatient.phone, 
    reference:{image:imageSrc,metadata:clickedPatient.metadata}
  })
  console.log('Save res ', res);
  //refresh
  getAppointmentData(appointmentData,selectedDate,setAppointmentData)
  onClose()
  setImageSrc("")

}
//Modal
function BasicModal(isOpen, onOpen, onClose,clickedPatient, setClickPatient, imageSrc, setImageSrc,appointmentData,selectedDate,setAppointmentData) {
  
  return (
    <>
    
    
      {isEmpty(clickedPatient) ? "" :
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{toTitleCase(clickedPatient.patient_name)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           
          <Text>{`Rs. ` + clickedPatient.metadata.fee + ` paid by `}</Text>
          
          <RadioGroup onChange={(a)=>setClickPatient(copyJSON(clickedPatient,{mode:a}))}>
          <Stack direction="row" spacing={3}>
          <Radio value='cash' >Cash </Radio>
          <Radio value='upi'>UPI </Radio>
          <Radio value='cheque'>Cheque </Radio>
          </Stack>
          </RadioGroup>
          
          {clickedPatient.mode=='upi' ? <WebcamCapture setImageSrc = {setImageSrc} /> : ""}
          </ModalBody>

          <ModalFooter>
           <Card>
          {imageSrc ? <img src={imageSrc}/> : ""}
          </Card>
            <Button colorScheme='blue' isDisabled={!((clickedPatient.mode && !imageSrc && clickedPatient.mode!="upi") || (clickedPatient.mode=='upi' && imageSrc)) ? true : false } //complex condition
            onClick={()=>saveTransaction(clickedPatient, imageSrc, setClickPatient, setImageSrc, onClose, appointmentData,selectedDate,setAppointmentData )}>Paid</Button>
          </ModalFooter>
          
        </ModalContent>
      </Modal> }
    </>
  )
}


export default function Ipd() {

  const [dateSelector, setDateSelector] = useState(false)
  const [selectedDate, setSelectedDate] = useState(dateFormat(new Date().toLocaleString("en-US")))
  const [appointmentData, setAppointmentData] = useState({})
  const [clickedPatient, setClickPatient] = useState()
  const [imageSrc, setImageSrc] = useState()
  const [printData, setPrintData] = useState({})
  const [hasAccess, setHasAccess] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const componentRef = useRef(); //print
  var user = getCookie('user');

  //states for ipd module
  const [monthSelector, setMonthSelector] = useState(new Date().getMonth() + 1)
  const [data, setData] = useState([])

  useEffect(()=>{
    
    //
    if(user.response.user_role.permissions.includes("billing")) {
      console.log('Doctor view');
      setHasAccess(true)
    }
    else {
      console.log('No doctor access');
      setHasAccess(false)
    }

    getEventData(monthSelector, setData);
    
  },[])

  //get data
  

  const fieldsData = {
      id: 'Id',
      subject: { name: 'Subject' },
      isAllDay: { name: 'IsAllDay' },
      startTime: { name: 'StartTime' },
      endTime: { name: 'EndTime' },
      doctor : {name: 'DoctorName'}
  }
//https://stackblitz.com/edit/react-vbh5dz?file=index.js,index.html 
//https://ej2.syncfusion.com/react/documentation/schedule/editor-template?_gl=1*18w17c6*_ga*MzE3OTEwNjI2LjE3MDQ3MzU4NjU.*_ga_41J4HFMX1J*MTcwNDczNTg2NS4xLjAuMTcwNDczNTg2NS4wLjAuMA..#add-additional-fields-to-the-default-editor

  return (
    hasAccess ? 
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <ScheduleComponent
        
        eventSettings={{ dataSource: data, fields: fieldsData}}
        startHour="7:00"
        endHour="15:00"
        firstDayOfWeek={1}
        workDays={[1,2,3,4,5,6]}
        readOnly={true}
        currentView={'WorkWeek'}
        navigating = {a=>{
          //console.log("Navigating dates ", a)
          if(a.action == "date" && new Date(a.currentDate).getMonth() != new Date(a.previousDate).getMonth()){
            //console.log("Dates changed - reload to month ", new Date(a.currentDate).getMonth())
            getEventData(new Date(a.currentDate).getMonth()+1, setData);
          }
        }


        }
         actionComplete={a=>{
          
          actionEvent(a)

          if(a.requestType=="eventCreated"){
            console.log('Action added',a.data, a.data[0].Id)
          } else if(a.requestType=="eventChanged"){
            console.log('Action Edited',a.data, a.data[0].Id)
          } else if(a.requestType=="eventRemoved"){
            console.log('Action Deleted',a.data, a.data[0].Id)
          }
         // console.log('Action call activity save api',a)
        }
        }
        //a.data , requestType: "eventCreated", "eventChanged", a.data.event Id, "eventRemoved"
          //timeScale={ {enable: true, interval: 60, slotCount: 2} }
          
     
         >
    <Inject services={[Day, Week, WorkWeek, Month, Agenda]}/>
    
</ScheduleComponent>


    </Box> :  <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Heading>You do not have access to view this page</Heading>
    </Box>
  );
}


/*
dataBound={{dataSource:rty}}
        workHours={{ highlight: true, start: '7:00', end: '16:00' }}
        startHour='7:00'
        endHour="19:00"
        firstDayOfWeek={'1'}
         onClick={a=>console.log('Clikced',a)}
         actionComplete={a=>console.log('action',a)}  
         
         timeScale={ {enable: true, interval: 60, slotCount: 2, majorSlotTemplate: null, minorSlotTemplate: null} }
         
         */
//Add event
//edit event
//delete event
//get event
//link -> link this to billing