
// Chakra imports
import { Box, Grid,
Heading, Stack, Button, Input,
TableContainer, Table, Tr, Td, Thead, Tfoot,
TableCaption, Th, Tbody,Switch, Icon } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import {FcApproval} from 'react-icons/fc'
import { getCookie } from "../../../utilities";

import TotalSpent from "./components/TotalSpent";

// Custom components
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  RadioGroup,
  Radio,
  AspectRatio
  
} from '@chakra-ui/react'

import Banner from "views/admin/billing/components/Banner";
import General from "views/admin/billing/components/General";
import Notifications from "views/admin/billing/components/Notifications";
import Projects from "views/admin/billing/components/Projects";
import Storage from "views/admin/billing/components/Storage";
import Upload from "views/admin/billing/components/Upload";
import ReactToPrint from 'react-to-print';

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import MiniCalendar from "components/calendar/MiniCalendar";
import React from "react";
import { useState, useEffect,useRef } from "react";
import { dateFormat } from "utilities";
import { isDefined } from "@chakra-ui/utils";
import { isEmpty } from "lodash";
import Webcam from "react-webcam";
import { Receipt } from "./components/Receipt";

var {dateFormatNormal, toTitleCase, copyJSON} = require('../../../utilities')

var axios = require('axios')
var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

async function getAppointmentData(appointmentData,selectedDate, setAppointmentData) {

  var res = await axios.get(`${REACT_APP_BASE_URL}/getCompletedAppointments?date=${selectedDate}`)
  console.log('app',res.data);
  if(isDefined(res.data.result)){
    setAppointmentData(res.data.result)
  }
  else  setAppointmentData([])
}



export default function Business() {

  const [dateSelector, setDateSelector] = useState(false)
  const [selectedDate, setSelectedDate] = useState(dateFormat(new Date().toLocaleString("en-US")))
  const [appointmentData, setAppointmentData] = useState({})
  const [clickedPatient, setClickPatient] = useState()
  const [imageSrc, setImageSrc] = useState()
  const [printData, setPrintData] = useState({})
  const [hasAccess, setHasAccess] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const componentRef = useRef(); //print
  var user = getCookie('user');

  useEffect(()=>{
    
    //
    if(user.response.user_role.permissions.includes("billing")) {
      console.log('Doctor view');
      setHasAccess(true)
    }
    else {
      console.log('No doctor access');
      setHasAccess(false)
    }


    getAppointmentData(appointmentData,selectedDate,setAppointmentData)
  },[])


  return (
   
    // This video will have equal sides
    <Box py='20'>
<AspectRatio maxW='100%' ratio={1}>
  <iframe
    title='naruto'
    src='https://datastudio.google.com/embed/reporting/ed6742c2-cc37-41cf-81f9-e403dde03717/page/H0W7C'
    allowFullScreen
  />
</AspectRatio>
</Box>
  );
}
