
import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import {getCookie, setCookie} from '../../../utilities'
import io from 'socket.io-client';
import { isEmpty } from "lodash";
import { isDefined } from "@chakra-ui/utils";
import ReCAPTCHA from "react-google-recaptcha";


// Chakra imports
import {
  Select,
  Radio,
  RadioGroup,
  ButtonGroup,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  TableContainer,
  Tr, Td, Table, Tbody, Image, HStack, InputLeftElement, InputLeftAddon
} from "@chakra-ui/react";
import Card from "../../../components/card/Card.js";

// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/819.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";
import { useHistory } from "react-router-dom";
import img from "assets/img/layout/CloudOPD_logo.png"
import {toTitleCase,formatAMPM, dateFormat} from '../../../utilities' 
import { useDispatch, useSelector } from "react-redux";
import {setSession, setTemplate} from '../../../redux/reducer'

var moment = require('moment')
var moment 
var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
var REACT_APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL
const socket = io(REACT_APP_SOCKET_URL);


function pop(data, aval) {
  console.log('----------------');
  console.table(data);
  console.log(aval);
  //console.log(data.appointmentfor);
}

async function clearAllFields(data, setData, setEnabled) {
  setEnabled(true)
  setData({
    "doctor_name":"",
    "phone":"",
    "email":"", 
    "clinic_name":"", 
    "clinic_address":"", 
    "website":"" 
  })
}

function validateReceptionInput(data, isenabled) {
  console.log('Checking fields');
  console.table(data)
  
  if (isEmpty(data.phone) || data.phone.length!=10)
    return {status:false, message:"Enter valid phone number"}
  else if (isEmpty(data.patient_name) && isenabled)
    return {status:false, message:"Enter patient name"}
  else if (isEmpty(data.address) && isenabled)
    return {status:false, message:"Enter patient address"}
  else if (isEmpty(data.birthdate) && isenabled)
    return {status:false, message:"Enter birthdate"}
  else if (isEmpty(data.gender) && isenabled)
    return {status:false, message:"Enter gender"}
  else if (isEmpty(data.complaint))
    return {status:false, message:"Enter complaint"}
  else if (data.doctor=="Select Doctor")
    return {status:false, message:"Please select a doctor"}
  else return {status:true}
}

async function bookAppointment(data, setData, setEnabled, isenabled) {
var sendData = {...data, opd_id: data.selected_doctor == "Deepti Pundle" ? 1 : data.selected_doctor == "Gauri Belsare" ? 2 : 3}
  //if(data.time == "morning") sendData
if(!validateReceptionInput(sendData, isenabled).status)
alert(validateReceptionInput(sendData, isenabled).message) 
else
saveAppointment(sendData, setData, setEnabled)
}

//save app
async function saveAppointment(data, setData, setEnabled) {
    
  console.log("Saving appointment")
 console.log(data);  
 //check phone
 var res_phone = await axios.get(process.env.REACT_APP_BASE_URL + `/getPatientDetails?phone=${data.phone}`)
  if(res_phone.data.status=="success") {
    console.log('ssss');
    data.patient_id = res_phone.data.result.patient_id
  }

 //create appointment
 if(data.patient_id) {
   console.log('existing patient');
   

   var res = await axios.post(`${REACT_APP_BASE_URL}/createAppointment`,{
    
    "patient_id":data.patient_id,
    "complaint":data.complaint, 
    "urgent":false,
    "created_by":{"user_id":"ONLINE","name":"ONLINE"},
   //"orgid":"BELSARE",
   "checkin":false,
   "opd_id":data.opd_id,
   "appointmentfor":data.appointmentfor, 
   "metadata":{
       "doctor":data.doctor,
       doctor_id: data.doctor == "Deepti Pundle" ? 6 : data.doctor == "Gauri Belsare" ? 3 : 2,
       user_id:data.doctor == "Deepti Pundle" ? 6 : data.doctor == "Gauri Belsare" ? 3 : 2,
       opd_id:data.opd_id
   }
})
if(res.data.status) {
  res.data.status=="success" ? alert(`Appointment booked for token ${res.data.result.token}`):
  alert(res.data.error);
  clearAllFields(data,setData,setEnabled)
}

console.log(res)

 //var res = await axios.post(`${{REACT_APP_BASE_URL}}/`)
 } else {
   console.log('Creating new patient');
  var res = await axios.post(`${REACT_APP_BASE_URL}/createNewPatient`,{
    "patient_name":data.patient_name,
  
  "phone":data.phone,
  "address":data.address,
  "isvip":data.isvip,
  "gender":data.gender,
  "birthdate":data.birthdate ? data.birthdate : "",
  "age": data.age ? data.age : "",
  "email":data.email
  })
  //now create an appointment
  console.table(res)
  if(res.data.status=="success")
  var res_1 = await axios.post(`${REACT_APP_BASE_URL}/createAppointment`,{
    
      "patient_id":res.data.result[0],
      "complaint":data.complaint, 
      "urgent":false,
     "created_by":{"user_id":"ONLINE","name":"ONLINE"}, //add user context
     //"orgid":"BELSARE",
     "checkin":false,
     "appointmentfor":data.appointmentfor, 
     "opd_id":data.opd_id,
     "metadata":{
         "doctor":data.doctor,
        
         doctor_id: data.doctor == "Deepti Pundle" ? 6 : data.doctor == "Gauri Belsare" ? 3 : 2,
         user_id:data.doctor == "Deepti Pundle" ? 6 : data.doctor == "Gauri Belsare" ? 3 : 2,
         opd_id:data.opd_id
     }
  })
  console.log(res_1)
  if(res_1.data && res_1.data.status=="success") {
    alert(`Appointment booked for token ${res_1.data.result.token}`)
    clearAllFields(data,setData, setEnabled)
  }
 }
 
}

function onChange(value) {
  console.log("Captcha value:", value);
}


function NewUserQuery() {
  // Chakra color mode
  const [data, setData] = useState({
    "doctor_name":"",
    "phone":"",
    "email":"", 
    "clinic_name":"", 
    "clinic_address":"", 
    "website":"" 

  })

  const [doctor, setDoctor] = useState([])
  const [aval, setAval] = useState([])
  const [isenabled, setEnabled] = useState(true)

  const dispatch = useDispatch()
  const state = useSelector(state=>state.templates.complaints)
  
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }


  );

  const bg = useColorModeValue("white", "#1B254B");
  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "none"
  );
  
  useEffect(async () => {
    // Update the document title using the browser API
    //setCookie({token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6IlNpZGRoZXNoIEtlbHVza2FyIiwidXNlcl9yb2xlIjp7InBlcm1pc3Npb25zIjpbImRvY3RvciIsInJlY2VwdGlvbiIsImJpbGxpbmciXX0sInBob25lIjoiNzc5NzQzOTA0NyIsIm9yZ2lkIjoiQkVMU0FSRSIsImlhdCI6MTY2NjYwOTAzNH0.sEdrRypvX6bxI6nijC4fuWTLqCZfDOluAWxCwqzl5IE"})
    axios.defaults.headers.common['x-access-token'] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6IlNpZGRoZXNoIEtlbHVza2FyIiwidXNlcl9yb2xlIjp7InBlcm1pc3Npb25zIjpbImRvY3RvciIsInJlY2VwdGlvbiIsImJpbGxpbmciXX0sInBob25lIjoiNzc5NzQzOTA0NyIsIm9yZ2lkIjoiQkVMU0FSRSIsImlhdCI6MTY2NjYwOTAzNH0.sEdrRypvX6bxI6nijC4fuWTLqCZfDOluAWxCwqzl5IE"
    
    console.log('state', state);
 

  },[]);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (

    
   <Box >
     <Flex direction='row' justify='space-between' align='center'>
     <Image align={'center'} src={img} py="1.5%" px="1.5%"/>
    </Flex>
    
    <Stack spacing="5">
      
    <Card py='2px' boxShadow={shadow} bm='0'>
    <Heading textAlign={'center'} size='xl' >Get Started</Heading>
    
  
    
    <Stack spacing="5"  pb="2" >
    
    <Input placeholder="Doctor Name" 
    value={data.doctor_name}
    onChange={e=>setData({...data,doctor_name:e.target.value})}
    ></Input>

    <Input placeholder="Phone number" type="number"
    value = {data.phone}
    onChange = {e=>setData({...data,phone:e.target.value})}
    ></Input>
   
   <Input placeholder="Email" py="3"
    value = {data.email}
    onChange = {e=>setData({...data,email:e.target.value})} ></Input>

   <Input placeholder="Clinic Name" py="3"
    value = {data.clinic_name}
    onChange = {e=>setData({...data,clinic_name:e.target.value})} ></Input>

    <Input placeholder="Clinic Address" py="3"
    value = {data.clinic_address}
    onChange = {e=>setData({...data,clinic_address:e.target.value})} ></Input>
    
    <Input placeholder="Website/ Google maps link" py="3"
    value = {data.website}
    onChange = {e=>setData({...data,website:e.target.value})} ></Input>

<ReCAPTCHA
    sitekey={process.env.REACT_APP_CAPTCHA}
    onChange={onChange}
  />
    
    </Stack>

    </Card>

    <ButtonGroup gap="4" px="5" >
    <Button colorScheme={"blue"} onClick={()=>bookAppointment(data, setData, setEnabled, isenabled)}>Request Access</Button>
    <Button colorScheme={"red"} 
    onClick={()=>clearAllFields(data, setData, setEnabled)
    }>Cancel</Button>

<Button colorScheme={"red"} 
    onClick={()=>console.log(data)
    }>Print</Button>

    </ButtonGroup>
    
    <Text px="5" fontWeight={"bold"}>By requesting access you agree to terms & conditions. 2023 - CloudOPD.com</Text>
      </Stack>
      
    </Box>
    
  );
}

export default NewUserQuery;
