import { ADD_VALUE, SET_VALUE, addValue, setValue } from "./actions"
import { getCookie } from "utilities"
import axios from "axios"

var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

const initialState = {
  session:{
      username:"",
      permissions:"",
      user_id:""
  },
  templates: {
      complaints:[],
      past_history:[],
      examination:[],
      diagnosis:[],
      advice:[],
      medication:[],
      med_set:[],
      tests:[]

  },
  filter_appointment: {
    
      "Sai Belsare":true,
      "Deepti Pundle":true,
      "Gauri Belsare":true
    
  }
}

export const reducer = (state = initialState, action) => {
  switch(action.type){
    case ADD_VALUE: {
      return {...state, ...action.payload}
    }
    case SET_VALUE: {
      return  action.payload
    }
    default:
      return state
  }
}

//Add API calls here instead of useEffect

//add session context
export const setSession = () => async (dispatch, getState) =>{
    var user = getCookie('user');
    axios.defaults.headers.common['x-access-token'] = user.token

    if(user){
    var session = {
        token:user.token,
        permissions:user.response.user_role.permissions,
        username:user.response.username,
        user_id:user.response.user_id


    }
    dispatch(addValue({session}))
}

}

//set templates
export const setTemplate = () => async (dispatch, getState) =>{
    var res = await axios.get(`${REACT_APP_BASE_URL}/getTemplate?language=english`)
    //var res = await axios.get(`${REACT_APP_BASE_URL}/getTemplate?orgid=BELSARE&language=english`)
    console.log('dispatch', res.data.result);
    var templates = {
        ...initialState.templates,
        ...res.data.result
  
    }

    dispatch(addValue({templates}))

}

//set doctor list
export const setDoctorList = () => async (dispatch, getState) =>{
  var res = await axios.get(`${REACT_APP_BASE_URL}/getDoctorList`)
  //var res = await axios.get(`${REACT_APP_BASE_URL}/getTemplate?orgid=BELSARE&language=english`)
  console.log('dispatch', res.data.result);
  var doctor_list = {
      ...res.data.doctor_list

  }

  dispatch(addValue({doctor_list}))

}

//set print prescription state
export const setPrintPrescription = (data) => (dispatch, getState) =>{
    
    var prescription = {
    patient_name:"",
    age:0,
    gender:"",
    token:"",
    phone:"",
    birthdate:"",
    complaint:"",
    past_history:"",
    examination:"",
    diagnosis:"",
    medication:"",
    advice:"",
    notes:"",
    fee:process.env.REACT_APP_BASE_FEE,
    follow_up:"",
    tests:""
}

prescription = {...prescription, ...data}
dispatch(addValue({prescription}))

}

//get all patients
export const listPatients = () => async (dispatch, getState) =>{
    var res = await axios.get(`${REACT_APP_BASE_URL}/admin/listPatients`)
    //console.log('dispatch', res.data.result);
    var patient_list = res.data.result
  
    dispatch(addValue({patient_list}))

}

//Update patient into
export const updatePatients = (data) => async (dispatch, getState) =>{
   data.updatedBy = {username:getState().session.username, user_id:getState().session.user_id}
    var res = await axios.post(`${REACT_APP_BASE_URL}/updatePatientDetails`, data)
    //console.log('dispatch', res.data.result);
    //var patient_list = res.data.result
    //Manipulate updater directly via state

    
    if(res.data.status=="success")
    dispatch(listPatients())

}