import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdMedicalServices,
  MdChecklist,
  MdSettings,
  MdAccountBalance,
  MdOutlineHealing,
  MdTrendingUp
} from "react-icons/md";

import {RiHotelBedFill} from "react-icons/ri"
import {FaMoneyCheck,FaUserPlus} from "react-icons/fa"
import {GrMoney} from "react-icons/gr"

// Admin Imports
import MainDashboard from "views/admin/reception"; 
import NFTMarketplace from "views/admin/marketplace";
import Billing from "views/admin/billing";
import DataTables from "views/admin/dataTables";
import DoctorView from "views/admin/doctor"
import PatientView from "views/patient/details"
import Appointments from "views/admin/appointments";
import Token from "views/addon/token"
import Business from "views/admin/business"
import PatientList from "views/patient/list";
import PastVisits from "views/patient/pastVisits";
import Queue from "views/addon/queueManagement"
import PublicBookAppointment from "views/addon/publicBookAppointment";
import Ipd from "views/admin/ipd";
import NewUserQuery from "views/addon/newUserQuery";

// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Reception",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    display:true
  },
  {
    name: "Doctor",
    layout: "/admin",
    path: "/doctor",
    icon: (
      <Icon
        as={MdMedicalServices}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: DoctorView,
    secondary: true,
    display:true
  },
  {
    name: "IPD",
    layout: "/admin",
    icon: <Icon as={RiHotelBedFill} width='20px' height='20px' color='inherit' />,
    path: "/ipd",
    component: Ipd,
    display:true
  },
  {
    name: "Billing",
    layout: "/admin",
    path: "/billing",
    icon: <Icon as={FaMoneyCheck} width='20px' height='20px' color='inherit' />,
    component: Billing,
    display:true
  },
  
  {
    name: "Patient Details",
    layout: "/admin",
    path: "/details",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: PatientView,
    display:false
  },
  
  {
    name: "Appointments",
    layout: "/admin",
    path: "/appointments",
    icon: <Icon as={MdChecklist} width='20px' height='20px' color='inherit' />,
    component: Appointments,
    display:true
  },
  {
    name: "Patients",
    layout: "/admin",
    path: "/patients",
    icon: <Icon as={MdOutlineHealing} width='20px' height='20px' color='inherit' />,
    component: PatientList,
    display:true
  },
  {
    name: "Admin",
    layout: "/auth",
    path: "/admin",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Appointments,
    display:false
  },
  {
    name: "Token",
    layout: "/auth",
    path: "/token",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Token,
    display:false
  },
  {
    name: "Trends",
    layout: "/admin",
    path: "/business",
    icon: <Icon as={MdTrendingUp} width='20px' height='20px' color='inherit' />,
    component: Business,
    display:true
  },
  {
    name: "Admin",
    layout: "/auth",
    path: "/token",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: Token,
    display:false
  },
  {
    name: "Queue Management",
    layout: "/admin",
    path: "/queue",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: Queue,
    display:true
  },
  {
    name: "Logout",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    display:true
  },
  {
    name: "Past Visits",
    layout: "/admin",
    path: "/visits",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: PastVisits,
    display:false
  },
  {
    name: "Book Appointment",
    layout: "/auth",
    path: "/appointment/booking",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: PublicBookAppointment,
    display:false
  },
  {
    name: "New User",
    layout: "/auth",
    path: "/signup",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: NewUserQuery,
    display:false
  }
];

export default routes;
