
import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import {getCookie, setCookie} from '../../../utilities'
import io from 'socket.io-client';
import { isEmpty } from "lodash";
import { isDefined } from "@chakra-ui/utils";

// Chakra imports
import {
  Select,
  Radio,
  RadioGroup,
  ButtonGroup,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  TableContainer,
  Tr, Td, Table, Tbody, Image, HStack, InputLeftElement, InputLeftAddon
} from "@chakra-ui/react";
import Card from "../../../components/card/Card.js";

// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/819.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";
import { useHistory } from "react-router-dom";
import img from "assets/img/layout/BelsareENT.png"
import {toTitleCase,formatAMPM, dateFormat} from '../../../utilities' 
import { useDispatch, useSelector } from "react-redux";
import {setSession, setTemplate} from '../../../redux/reducer'

var moment = require('moment')
var moment 
var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
var REACT_APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL
const socket = io(REACT_APP_SOCKET_URL);


function pop(data, aval) {
  console.log('----------------');
  console.table(data);
  console.log(aval);
  //console.log(data.appointmentfor);
}

async function clearAllFields(data, setData, setEnabled) {
  setEnabled(true)
  setData({
  appointmentfor: false ? "" : dateFormat(Date.now()),
  patient_name:"",
  phone:"",
  time:"evening",
  gender:"M",
  address:"",
  birthdate:"",
  doctor:"Select Doctor",
  complaint:""
  })
}
async function validatePhone(data,setData, setEnabled) {

  console.log("Validating ", data.phone)
  var res = await axios.get(process.env.REACT_APP_BASE_URL + `/getPatientDetails?phone=${data.phone}`)
  console.log(res)
  if(res.data.status == "success"){
  setEnabled(false)
  // check if patient has appointment
  //set dummy val fields
  console.log('Patient exists', res.data);
  console.log(Object.keys(res.data));
  if(isDefined(res.data.result.appointment_id)) {
    //Appointment exists return
    alert(`Appointment already taken for the number. Token number is ${res.data.result.token}`)
    clearAllFields(data,setData,setEnabled)
    
  } else 
  setData({...data, 
    //patient_name:toTitleCase(res.data.result.patient_name),
    //gender:res.data.result.gender, 
    //isvip:res.data.result.isvip,
    //email:res.data.result.email, 
    //birthdate:dateFormat(res.data.result.birthdate), 
    //age:res.data.result.age,
    //address:res.data.result.address,
    patient_id:res.data.result.patient_id })
}
else   setEnabled(true)

}


async function checkDoctorAvailability(data, setDoctor) {
  //Based on number of appointments and holiday
  var res = await axios.get(process.env.REACT_APP_BASE_URL + '/users/check2?date=' + data.appointmentfor)
  console.log('Doctor availability', res.data.result);
  var doctor_list = [] //User id for doctors


  res.data.result.map(a=>{
    if(a.isavailable) doctor_list.push(a.name)
    
  })
  console.log('Available Drs:',doctor_list)
  setDoctor(res.data.result)
  //setData(data)
  //setData(res.data.result)
  // set doctor here as well
  
}

function reachTime(message, aval) {
  console.log('Message', message);
  var tks = message.split(" ")
  tks = tks[tks.length-1]
  console.log('Token', tks);
  console.log('Availability', aval);
}

function validateReceptionInput(data, isenabled) {
  console.log('Checking fields');
  console.table(data)
  
  if (isEmpty(data.phone) || data.phone.length!=10)
    return {status:false, message:"Enter valid phone number"}
  else if (isEmpty(data.patient_name) && isenabled)
    return {status:false, message:"Enter patient name"}
  else if (isEmpty(data.address) && isenabled)
    return {status:false, message:"Enter patient address"}
  else if (isEmpty(data.birthdate) && isenabled)
    return {status:false, message:"Enter birthdate"}
  else if (isEmpty(data.gender) && isenabled)
    return {status:false, message:"Enter gender"}
  else if (isEmpty(data.complaint))
    return {status:false, message:"Enter complaint"}
  else if (data.doctor=="Select Doctor")
    return {status:false, message:"Please select a doctor"}
  else return {status:true}
}

async function bookAppointment(data, setData, setEnabled, isenabled) {
  console.log("Data Sample submitted", data)
var sendData = {...data}
  //if(data.time == "morning") sendData
if(!validateReceptionInput(sendData, isenabled).status)
alert(validateReceptionInput(sendData, isenabled).message) 
else
saveAppointment(sendData, setData, setEnabled)
}

//save app
async function saveAppointment(data, setData, setEnabled) {
    
  console.log("Saving appointment")
 console.log(data);  
 //check phone
 var res_phone = await axios.get(process.env.REACT_APP_BASE_URL + `/getPatientDetails?phone=${data.phone}`)
  if(res_phone.data.status=="success") {
    console.log('ssss');
    data.patient_id = res_phone.data.result.patient_id
  }

 //create appointment
 if(data.patient_id) {
   console.log('existing patient');
   

   var res = await axios.post(`${REACT_APP_BASE_URL}/createAppointment`,{
    
    "patient_id":data.patient_id,
    "complaint":data.complaint, 
    "urgent":false,
    "created_by":{"user_id":"ONLINE","name":"ONLINE"},
   //"orgid":"BELSARE",
   "checkin":false,
   "opd_id":data.opd_id,
   "appointmentfor":data.appointmentfor, 
   "metadata":{
       "doctor":data.doctor,
        opd_id:data.opd_id,
        doctor_id:data.doctor_id
   }
})
if(res.data.status) {
  res.data.status=="success" ? alert(`Appointment booked for token ${res.data.result.token}`):
  alert(res.data.error);
  clearAllFields(data,setData,setEnabled)
}

console.log(res)

 //var res = await axios.post(`${{REACT_APP_BASE_URL}}/`)
 } else {
   console.log('Creating new patient');
  var res = await axios.post(`${REACT_APP_BASE_URL}/createNewPatient`,{
    "patient_name":data.patient_name,
  
  "phone":data.phone,
  "address":data.address,
  "isvip":data.isvip,
  "gender":data.gender,
  "birthdate":data.birthdate ? data.birthdate : "",
  "age": data.age ? data.age : "",
  "email":data.email
  })
  //now create an appointment
  console.table(res)
  if(res.data.status=="success")
  var res_1 = await axios.post(`${REACT_APP_BASE_URL}/createAppointment`,{
    
      "patient_id":res.data.result[0],
      "complaint":data.complaint, 
      "urgent":false,
     "created_by":{"user_id":"ONLINE","name":"ONLINE"}, //add user context
     //"orgid":"BELSARE",
     "checkin":false,
     "appointmentfor":data.appointmentfor, 
     "opd_id":data.opd_id,
     "metadata":{
         "doctor":data.doctor,
          opd_id:data.opd_id
     }
  })
  console.log(res_1)
  if(res_1.data && res_1.data.status=="success") {
    alert(`Appointment booked for token ${res_1.data.result.token}`)
    clearAllFields(data,setData, setEnabled)
  }
 }
 
}



function PublicBookAppointment() {
  // Chakra color mode
  const [data, setData] = useState({
    appointmentfor: false ? "" : dateFormat(Date.now()),
    patient_name:"",
    phone:"",
    time:"evening",
    gender:"M",
    address:"",
    birthdate:"",
    doctor:"Select Doctor",
    complaint:""

  })

  const [doctor, setDoctor] = useState([])
  const [aval, setAval] = useState([])
  const [isenabled, setEnabled] = useState(true)

  const dispatch = useDispatch()
  const state = useSelector(state=>state.templates.complaints)
  
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }


  );

  const bg = useColorModeValue("white", "#1B254B");
  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "none"
  );
  
  useEffect(async () => {
    // Update the document title using the browser API
    //setCookie({token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6IlNpZGRoZXNoIEtlbHVza2FyIiwidXNlcl9yb2xlIjp7InBlcm1pc3Npb25zIjpbImRvY3RvciIsInJlY2VwdGlvbiIsImJpbGxpbmciXX0sInBob25lIjoiNzc5NzQzOTA0NyIsIm9yZ2lkIjoiQkVMU0FSRSIsImlhdCI6MTY2NjYwOTAzNH0.sEdrRypvX6bxI6nijC4fuWTLqCZfDOluAWxCwqzl5IE"})
    axios.defaults.headers.common['x-access-token'] = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6IlNpZGRoZXNoIEtlbHVza2FyIiwidXNlcl9yb2xlIjp7InBlcm1pc3Npb25zIjpbImRvY3RvciIsInJlY2VwdGlvbiIsImJpbGxpbmciXX0sInBob25lIjoiNzc5NzQzOTA0NyIsIm9yZ2lkIjoiQkVMU0FSRSIsImlhdCI6MTY2NjYwOTAzNH0.sEdrRypvX6bxI6nijC4fuWTLqCZfDOluAWxCwqzl5IE"
    
    dispatch(setTemplate())
    checkDoctorAvailability(data, setDoctor)
   

  },[]);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (

    
   <Box >
     <Flex direction='row' justify='space-between' align='center'>
     <Image align={'center'} src={img} py="1.5%" px="1.5%"/>
    </Flex>
    
    <Stack spacing="5">
      
    <Card py='2px' boxShadow={shadow} bm='0'>
    <Heading textAlign={'center'} size='xl' >Book new appointment</Heading>
    {doctor.length>0 ? <div>
      <Text>Available Time</Text>
      {doctor.map(a=>a.isavailable ? <Text>{"Dr. " + a.name + " " + a.next }</Text> : "")

      }
    </div> : ""}
    
    <Stack spacing="5"  pb="2" >
    <InputGroup py="2">
    <InputLeftAddon>Appointment For</InputLeftAddon>
    <Input placeholder="Appointment for" type="date"
    value = {data.appointmentfor}
    min = {moment().format('YYYY-MM-DD')}
    max= {moment(Date.now() + 2*24*60*60*1000).format('YYYY-MM-DD')}
    onChange = {e=>{
      
      if(new Date(e.target.value).getDay() == 0) alert ("Sunday is a holiday")
      else if((new Date() == new Date(e.target.value))) setData({...data,appointmentfor:e.target.value})
      else if((new Date() > new Date(e.target.value))) alert ("Invalid date")
      else
      setData({...data,appointmentfor:e.target.value})
      
      }}
      onBlur={()=>checkDoctorAvailability(data, setDoctor)}
      ></Input>
    </InputGroup>
   
    <Input placeholder="Phone number" type="number"
    value = {data.phone}
    onChange = {e=>setData({...data,phone:e.target.value})}
    onBlur={()=>validatePhone(data, setData, setEnabled)}></Input>
   {isenabled ? <div>
    <Input placeholder="Name" 
    value={data.patient_name}
    onChange={e=>setData({...data,patient_name:e.target.value})}
    ></Input>

    <RadioGroup py="3" value={ data.gender ? data.gender : "M"} onChange={e=>setData({...data, gender:e})}
   >
    <Stack direction="row">
      <Radio value="M" >Male</Radio>
      <Radio value="F" >Female</Radio>
    </Stack>
  </RadioGroup>

    <Input placeholder="Address" py="3"
    value = {data.address}
    onChange = {e=>setData({...data,address:e.target.value})} ></Input>
    
    <InputGroup py="3">
    <InputLeftAddon>Date of birth</InputLeftAddon>
    <Input type="date"
    value = {data.birthdate}
    onChange = {e=>setData({...data,birthdate:e.target.value})} ></Input>
    </InputGroup>
    <Text>*Date of birth is for patients age</Text>

    </div> : ""
}
  
    <Select placeholder="Complaint" variant="filled" 
    value={data.complaint}
    onChange={e=>setData({...data,complaint:e.target.value})}>
     {state.map(e=><option value={e.name}>{e.name}</option>)  }
    
    </Select>

    {doctor.length>0 ?
    <Select placeholder="Select Doctor" variant="filled"
    value={data.temp}
    onChange={e=>{
      //console.log("Magic man",JSON.parse(e.target.value))
      setData({...data,temp:e.target.value,doctor:JSON.parse(e.target.value).name, opd_id:JSON.parse(e.target.value).opd_id, doctor_id:JSON.parse(e.target.value).doctor_id})
      }}>
     {doctor.map(a=> a.isavailable ? <option value={JSON.stringify(a)}>{'Dr. ' + a.name + " " + a.next}</option> : "" )  }
    
    </Select> : ""}

    
    </Stack>

    </Card>

    <ButtonGroup gap="4" px="5" >
    <Button colorScheme={"blue"} onClick={()=>bookAppointment(data, setData, setEnabled, isenabled)}>Book</Button>
    <Button colorScheme={"red"} 
    onClick={()=>clearAllFields(data, setData, setEnabled)
    }>Cancel</Button>

<Button colorScheme={"red"} visibility = "hidden"
    onClick={()=>console.log("Printing data",data)
    }>Print</Button>

    </ButtonGroup>
    
    <Text px="5" fontWeight={"bold"}>For urgent appointments and any other queries please call 8446809809/ 8237807807</Text>
      </Stack>
      
    </Box>
    
  );
}

export default PublicBookAppointment;

//conditional selection for date and doctor's availability
/*
1 to 15 call at 6.30
15 to 30 call at 7.30
30 to 45 at 8.30
45 to 65 at 9.30
65 to 100 at 10
*/

