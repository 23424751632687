import {
  Button,
  Flex,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack, Radio,
  RadioGroup,
  useDisclosure
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import { useEffect, useState } from "react";

import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import {dateFormat, toTitleCase, formatAMPM, getCookie} from '../../../../utilities'
import { isDefined } from "@chakra-ui/utils";


const axios = require('axios')

var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL


async function changeDoctor(data) {

console.log("Changing doctor", data )
var res = await axios.post(`${REACT_APP_BASE_URL}/admin/editAppointment`, data  )
if(res.data.status=='success')
alert('Update success')
else
(alert(`Fail ${res.data.error}`))

 
}

async function deleteAppointment(data) {
  console.log(data)
 
  var res = await axios.post(`${REACT_APP_BASE_URL}/admin/editAppointment`, data  )
if(res.data.status=='success')
alert('Delete success')
else
(alert(`Fail ${res.data.error}`))

}

async function checkinToggle(token, appointment_id, dir, disp, setDisp) {
  if(disp){
  setDisp(false)
  var res = await axios.get(`${REACT_APP_BASE_URL}/checkinPatient?dir=${dir}&token=${token}&appointment_id=${appointment_id}`)
  setDisp(true)
  } 
  //emit update event
  }

function checkFlag(cells) {
  return cells.map((cell, index) => {
    if(cell.column.Header=='URGENT')
    console.log(cell.column.Header, cell.value, index)
    //console.log(cells)
    if((cell.column.Header=='URGENT' && cell.value) || (cell.column.Header=='VIP' && cell.value)){
        //console.log('True')
        return true
    } else {//console.log('False'); return false
    }
    
  })
  console.log('Checking flag')
}
  //Modal
  function changeDoctorModal(isOpen, onOpen, onClose,selectedDoctor, setSelectedDoctor, changeDoctorVar, setChangeDoctorVar,changeDoctor,deleteAppointment) {
  
    return (
      
      <>
        

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
           
            <ModalHeader> {changeDoctorVar.current_doctor ? 'Change Doctor' : 'Delete Appointment'}</ModalHeader>
            <ModalCloseButton />
            { changeDoctorVar.current_doctor ? <div>
            <ModalBody>
            <Text>Patient name: {changeDoctorVar.patient_name}</Text>
            <Text>Current selection: {changeDoctorVar.current_doctor}</Text>
            <Select value={selectedDoctor} 
                          onChange={(a)=>setSelectedDoctor(a.target.value)}>
                          {getCookie('doctor_filter').map(a=><option value={a.username}>{a.username}</option>)}
                           
                          </Select>
                        
      </ModalBody>
            <ModalFooter>
              <Button colorScheme='red' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button colorScheme='blue' mr={3}
              onClick={()=>{changeDoctor({
                ...changeDoctorVar,
                action:'change_doctor',
                doctor:selectedDoctor
              }, selectedDoctor);
              setChangeDoctorVar({})
              onClose()}}>
                Change
              </Button>
            </ModalFooter> </div> : ""}

            {changeDoctorVar.action == 'delete' ? <div>
              <ModalBody>
                <Text>{`Are you sure to delete appointment for ${changeDoctorVar.patient_name} ?`}</Text>
              </ModalBody>
              <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button colorScheme='red' mr={3}
              onClick={()=>{deleteAppointment(changeDoctorVar);
              setChangeDoctorVar({})
              onClose()}}>
                Delete
              </Button>
            </ModalFooter>
            </div> : ""

            }
          </ModalContent>
        </Modal>
      </>
    )
  }
  

  

export default function ColumnsTable(props) {

  //const [dropDownOpen, setDropDownOpen] = useEffect('false')

  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 200 } 
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [disp, setDisp] = useState(true)
  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [selectedDoctor, setSelectedDoctor] = useState('Sai Belsare')
  const [dispDeleteButton, setDispDeleteButton] = useState(false)

  const [changeDoctorVar, setChangeDoctorVar] = useState({})
  const [delAppointmentVar, setDelAppointmentVar] = useState({})


  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "scroll" }}>
      {changeDoctorModal(isOpen, onOpen, onClose, selectedDoctor, setSelectedDoctor, changeDoctorVar, setChangeDoctorVar,changeDoctor,deleteAppointment)}
      
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} >
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index} >
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "NAME") {
                    data = (
                      <Flex align='center'>
                        <a href={`/#/admin/visits/${cell.row.original.patient_id}`}>
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                         {toTitleCase(cell.value)}
                        </Text>
                        </a>
                      </Flex>
                    );
                  } else if (cell.column.Header === "PHONE") {
                    data = (
                      <Flex align='center'>
                        <Text
                          me='10px'
                          color={textColor}
                          fontSize='sm'
                          fontWeight='700'>
                         {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "TOKEN") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                       {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "DOCTOR") {
                    data = (
                   
                        <Text color={textColor} fontSize='sm' fontWeight='700'
                        onClick={()=>console.log('doctor value')}>
                         {cell.value}
                        <Button onClick={()=> {setChangeDoctorVar({
                          appointment_id:cell.row.original.appointment_id,
                          current_doctor:cell.row.original.doctor,
                          patient_name:cell.row.original.patient_name
                        }) ; onOpen()}}>✏️</Button>
                        </Text>
                       
                    );
                  } else if (cell.column.Header === "COMPLAINT") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'
                      onClick={()=>console.log('doctor value')}>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "TIME") {
                    data = (
                      <div>
                      
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                       { formatAMPM(new Date(cell.value)) + " (" + cell.value.split('T')[0] + ") " + cell.row.original.created_by.name.split(' ')[0]}
                      </Text> 
                        
                       { cell.row.original.ischecked ? "" : <Button 
                      
                       colorScheme={'red'}
                       size='sm'
                       width='50px'
                       onClick = {()=>{setChangeDoctorVar({
                         action:'delete',
                         appointment_id:cell.row.original.appointment_id,
                         patient_name:cell.row.original.patient_name,
                         ischecked:cell.row.original.ischecked
                       });
                       onOpen()}}>Delete</Button>}
                       </div>
                    
                    ); }
                    else if (cell.column.Header === "STATUS") {
                      data = (
                        <div>
                        <Text color={textColor} fontSize='sm' fontWeight='700'
                        
                        onClick={()=>{
                          var dir =   cell.value ? "out" : "in"
                          checkinToggle(cell.row.original.token, cell.row.original.appointment_id, dir, disp, setDisp)}
                         }
                        _hover={{ cursor: "pointer" }}
                        >
                          {cell.value ? "Waiting" : "Not Arrived"}
                          
                        </Text>
                        </div>
                      ); }
                      else if (cell.column.Header === "AT") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value ? formatAMPM(new Date(cell.value)) : "---"}
                          </Text>
                        );}
                      else if (cell.column.Header === "WAITTIME") {
                        data = (
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value ? cell.value : "---"}
                          </Text>
                        );}

                        else if (cell.column.Header === "URGENT") {
                          data = (
                            <Text color={textColor} fontSize='sm' fontWeight='900' bgColor={'tomato'} color='white' align='center'>
                              {cell.value ? "URGENT":""}
                            </Text>
                          );}
                          else if (cell.column.Header === "VIP") {
                            data = (
                              <Text color={textColor} fontSize='sm' fontWeight='900'  bgColor={'tomato'} color='white' align='center'>
                                {cell.value ? "VIP":""}
                              </Text>
                            );}
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
