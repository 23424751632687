
import React from "react";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import {getCookie, setCookie} from '../../../utilities'
import io from 'socket.io-client';

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Stack,
  TableContainer,
  Tr, Td, Table, Tbody, Image, HStack
} from "@chakra-ui/react";
import Card from "../../../components/card/Card.js";

// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/819.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";
import { useHistory } from "react-router-dom";
import img from "assets/img/layout/BelsareENT.png"
import {toTitleCase,formatAMPM} from '../../../utilities' 
import { find } from "lodash";

var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL
var REACT_APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL
const socket = io(REACT_APP_SOCKET_URL);


async function refresh(stats, setStats, setL1, setL2) {
  var res = await axios.get(`${REACT_APP_BASE_URL}/tokenDetails`)
 
  if(res.data.status=="fail"){
    console.log('Login failed');
 
} else {
  setStats(res.data.result)
  console.table(res.data.result);
  var _L1 = []
  for(var i=0; i<res.data.result.length; i++) {
    if(res.data.result[i].doctor == 'Gauri Belsare') {
      _L1.push(res.data.result[i])
      break;
    }
  }
  for(var i=0; i<res.data.result.length; i++) {
    if(res.data.result[i].doctor == 'Sai Belsare') {
      _L1.push(res.data.result[i])
      break;
    }
  }
  for(var i=0; i<res.data.result.length; i++) {
    if(res.data.result[i].doctor == 'Deepti Pundle') {
      _L1.push(res.data.result[i])
      break;
    }
  }
  
  setL1(_L1)
  setL2(res.data.result.filter(x => !_L1.includes(x)))
}
  
}

function Token() {
  // Chakra color mode
  const [stats, setStats] = useState([])

  const [L1, setL1] = useState([])
  const [L2, setL2] = useState([])

  const [screenSaver, setScreenSaver] = useState(false);
  

  //const [time, setTime] = useState(new Date())
  const history = useHistory()
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }


  );

  const bg = useColorModeValue("white", "#1B254B");
  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "none"
  );
  
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  useEffect(async () => {
    // Update the document title using the browser API

    var user = getCookie('user');
    console.log("Cookie",user)
    //redirect if cookie is not available
    if(!user) history.push('/auth/sign-in')
// Or you can make an await call
   let screenLock = await navigator.wakeLock.request('screen');
    //
    refresh(stats, setStats, setL1, setL2)

    socket.on('queue_update', (a) => {
      refresh(stats, setStats, setL1, setL2)
      console.log('queue_update',a);
    });

    setInterval(()=> {
                refresh(stats, setStats, setL1, setL2);
              },60000)


  },[]);

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
screenSaver ? <Box></Box>: 
    
   <Box >
     <Flex direction='row' justify='space-between' align='center'>
     <Image align={'center'} src={img} py="1.5%" px="1.5%"/>
     
    </Flex>
    
    <Stack direction='row' >
      
    <Card py='2px' boxShadow={shadow} bm='0'>
    <Heading textAlign={'center'} size='xl' >Current Token</Heading>
    <HSeparator/>
    {stats.length>0 ? <div >
     {/*<Heading textAlign={'center'} fontSize='250'>{stats[0].token }</Heading>*/}
     {L1.map((a,i)=> { return <div>
      <Heading marginTop="15%" textAlign={'center'} size='xl' >{toTitleCase(a.patient_name)}</Heading>
      <Heading textAlign={'center'} size='xl' >{a.doctor=='Sai Belsare' ? 'Consultation Room 2' : 'Consultation Room 1'}</Heading>
     </div>})}
    

      </div> : "" }
      </Card>
    
      <Card py='2px' h="100%" boxShadow={shadow} >
      
      <Heading textAlign={'center'} size='xl' >Next Token</Heading>
      <HSeparator/>
      <Table>
        <Tbody>
        
          {L2.map((a,i)=> {if(i<5)return <Tr>
          <Td><Heading textAlign={'center'} size='xl' >{toTitleCase(a.patient_name)}</Heading></Td>
          {/*<Td><Heading textAlign={'center'} size='xl' >{a.token} </Heading></Td>*/}
        </Tr>
          })}
        </Tbody>
      </Table>
      
      </Card>
      
      </Stack>
      
    </Box>

  );
}

export default Token;

//
/*<Button
            fontSize='sm'
            me='0px'
            mb='26px'
            py='15px'
            h='50px'
            borderRadius='16px'
            bg={googleBg}
            color={googleText}
            fontWeight='500'
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}>
            <Icon as={FcGoogle} w='20px' h='20px' me='10px' />
            Sign in with Google
          </Button>
          <Flex align='center' mb='25px'>
            <HSeparator />
            <Text color='gray.400' mx='14px'>
              or
            </Text>
            <HSeparator />
          </Flex>

    */