
// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,Button, ButtonGroup,Input ,Stack,InputGroup,InputLeftElement,InputRightElement,
  Radio, RadioGroup, Checkbox, Text,Textarea, Heading, Table, Th, Tr, Td, Image, FormControl
} from "@chakra-ui/react";
// Assets

import FilePreview from "react-file-preview-latest";


import React from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";

import {PhoneIcon} from '@chakra-ui/icons'
import Card from "components/card/Card";
import HistoryCard from "components/historyCard/HistoryCard"
import { useLocation } from "react-router-dom";

import { useEffect, useState, useRef } from "react";
import { isDefined, isDisabled } from "@chakra-ui/utils";
import { isEmpty, lowerCase } from "lodash";
import {getCookie, setCookie} from '../../../utilities'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {setSession, setTemplate} from '../../../redux/reducer'

var {toTitleCase, dateFormat} = require('../../../utilities')

var REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL

//get files
async function getFiles(setUpFiles,patient_id) {
  var res = await axios.get(`${REACT_APP_BASE_URL}/getFiles?patient_id=${patient_id}`)
  if(res.data.status=="success") {
    console.log('files found', res.data.result);
    setUpFiles(res.data.result)
  }
}

//function definitions
async function updatePatientPage(setPatient,patient_id, setHistory) {
var res = await axios.get(`${REACT_APP_BASE_URL}/getPatientHistory?patient_id=${patient_id}`)
if(res.data.status=="success") {
  console.log('all patient data');
  console.table(res.data.result)
  setHistory(res.data.result)
  setPatient({
    patient_name:toTitleCase(res.data.result[0].patient_name),
    age:res.data.result[0].age,
    gender:res.data.result[0].gender=='M' ? 'Male' : 'Female',
    token:res.data.result[0].token,
    phone:res.data.result[0].phone,
    birthdate:dateFormat(res.data.result[0].birthdate),
    complaint:res.data.result[0].complaint,
    appointment_id:res.data.result[0].appointment_id,
    fee:res.data.result[0].metadata.fee ? res.data.result[0].metadata.fee:process.env.REACT_APP_BASE_FEE, //default fee
    diagnosis:res.data.result[0].metadata.diagnosis ? res.data.result[0].metadata.diagnosis:"",
    examination:res.data.result[0].metadata.examination ? res.data.result[0].metadata.examination:"",
    medication:res.data.result[0].metadata.medication ? res.data.result[0].metadata.medication:" ",
    notes:res.data.result[0].metadata.notes ? res.data.result[0].metadata.notes:"",
    past_history:res.data.result[0].metadata.past_history ? res.data.result[0].metadata.past_history:"",
    advice:res.data.result[0].metadata.advice ? res.data.result[0].metadata.advice:"",
    follow_up:res.data.result[0].metadata.follow_up ? res.data.result[0].metadata.follow_up:"",
    iscomplete:res.data.result[0].iscomplete,
    doctor:res.data.result[0].metadata.doctor,
    address:res.data.result[0].address
    
  })
  
}

}

//Upload file
async function uploadFile(selectedFile,patient_id, createdby,setSelectedFile, fileDescription, setDescription) {

  var formData = new FormData()
  formData.append('file',selectedFile)
  const config = {
    headers: {
        'content-type': 'multipart/form-data',
        'patient_id' : patient_id,
        'createdby' : createdby,
        'orgid' : 'BELSARE',
        'description':fileDescription
    }
}
  var res = await axios.post(`${REACT_APP_BASE_URL}/file/upload`,formData,config)
  console.log('res', res.data);
  if(res.data=="OK") {
    setSelectedFile("")
    setDescription("")
    
  }
  }

  
export default function PastVisits() {

  const componentRef = useRef(); //print
  const state = useSelector(state=>state)
  const dispatch = useDispatch()

  //var _history = useHistory()
  const location = useLocation();
  const id = location.pathname.split("/")
  const patient_id = id[3]
  console.log('In patient details page',id[3]);
  //var user = getCookie('user')

  
  const [hasAccess, setHasAccess] = useState(false);
  const [history, setHistory] = useState([])
  
  const [fileDescription, setDescription] = useState("")
  const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);



  const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
    console.log('Selected file', selectedFile);
		//setIsSelected(true);
	};

	const handleSubmission = () => {
    
    uploadFile(selectedFile,patient_id,state.session.username,setSelectedFile, fileDescription, setDescription)
	};


  const [upFiles, setUpFiles] = useState([])
  const [patient, setPatient] = useState({
    patient_name:"",
    age:0,
    gender:"",
    token:"",
    phone:"",
    birthdate:"",
    complaint:"",
    past_history:"",
    examination:"",
    diagnosis:"",
    medication:"",
    advice:"",
    notes:"",
    fee:process.env.REACT_APP_BASE_FEE,
    follow_up:"",
    tests:""


  })


  useEffect(async () => {
    // Update the document title using the browser API
    var user = getCookie('user');

    //set dispatch actions
    dispatch(setSession())
    dispatch(setTemplate())

    //
    if(user.response.user_role.permissions.includes("doctor")) {
      console.log('Doctor view');
      setHasAccess(true)
    }
    else {
      console.log('No doctor access');
      setHasAccess(false)
    }

    updatePatientPage(setPatient,patient_id,setHistory)
    getFiles(setUpFiles,patient_id)

  },[]);

  
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  
  return (
      hasAccess ?
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
     
      {/*<!Form layout>*/}
    
      <Stack spacing={3}>
      <Text fontSize='4xl'>{patient.patient_name}</Text>
      <Stack spacing={3} direction="row">
      <Text fontSize='2xl'>{patient.age} year</Text>
      <Text fontSize='2xl'>{patient.gender}</Text>
      </Stack>
      <Text fontSize="lg"><b>{`Phone: ${patient.phone}, Address: ${patient.address}`}</b></Text>

      <Heading>Files</Heading>
      
      <Card>
      <Stack spacing={3} >
      <input type="file" name="file" onChange={changeHandler} />
			
      {selectedFile ? <FilePreview
        type={"url"}
        url={URL.createObjectURL(selectedFile)}
       // onError={this.onError}
      /> : ""}
        <Input placeholder="File description" onChange={a=>setDescription(a.target.value)}
        value={fileDescription}></Input>
				<Button colorScheme={'blue'} width="100px" onClick={handleSubmission} 
          isDisabled = {selectedFile ? false : true} >Upload</Button>
			
 
 
      </Stack>
      </Card>
      

      
    <Heading>Past Visits</Heading>
      
      {history.map((a,i)=> { 
              return <HistoryCard data={a}></HistoryCard>  } )}
      
     <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }}
       >
    {
   
      upFiles ? upFiles.map(a=><div>
       <a href={`https://saibelsare.com/images/` + a.key} target="_blank"> <FilePreview
        type={"url"}
        url={'https://saibelsare.com/images/' + a.key}
        
       // onError={this.onError}
      />
      <Text>{a.description}</Text> </a> </div>) : ""
    }
    </SimpleGrid > 

      </Stack>
    </Box>
    : <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
    <Heading>You do not have access to view this page</Heading>
  </Box>
  );
}


//complaints should have autofill
//address should have autofill
//token number is for every patient - validity 1 day, 4am
//token response system
//auto gen message before 1 hour

//chief complaints
//Examination
//complaint, past history, allergy, examination, diagnosis, medication, advice, notes
//Past history. allergy
//Print: prescription document. 
//diagnosis, medication, advice (advice & medication - instructions in marathi) follow up date
// 1 -- x -- 1 10 Days After food
// 0.5 -- x -- x 2 days
// Examination, diagnosis,  medication, Date - click for all details. close then only date


//add medication templates 8 to 10 combination of drugs
/*{history.map((a)=>
  <Text>Date:
    Complaint:
    Doctor:
    Examination:
    Medication:
    Past History:
    Diagnosis:
    Advice:
    Notes:
    Fee:
    Follow up:
  </Text>*/